import http from "./http-common";
import { useAuth } from "../authProvider/AuthContextProvider";

const useRequest = () => {
  const { token } = useAuth();
  const postRequest = (url, body) => {
    return http.post(url, body, {
      headers: {
        Authorization: `NoChinta ${token}`,
      },
    });
  };

  const putRequest = (url, body) => {
    return http.put(url, body, {
      headers: {
        Authorization: `NoChinta ${token}`,
      },
    });
  };

  const deleteRequest = (url) => {
    return http.delete(url, {
      headers: {
        Authorization: `NoChinta ${token}`,
      },
    });
  };

  return [postRequest, putRequest, deleteRequest];
};

export default useRequest;
