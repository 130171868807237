import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate, useParams } from "react-router-dom";
import useGetRequest from "../../apiServices/useGetRequest";
import useRequest from "../../apiServices/useRequest";
import { Button, Divider } from "@mui/material";
import AddSessionModal from "./AddSessionModal";
import Swal from "sweetalert2";
import tosterMessage from "../../components/common/ToasterMessage";
import { TemplateHandler } from "easy-template-x";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import moment from "moment";

const OrderDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  let { id } = useParams();

  const [postRequest, putRequest] = useRequest();
  let navigate = useNavigate();
  const [isFetching, orderDetails, setOrderDetails] = useGetRequest(
    `/order/${id}`
  );
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelSchedule = (sessionNo) => {
    Swal.fire({
      title: "Are you sure you want to cancel this session?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, cancel it!",
      background: "#fffff",
      color: "black",
    }).then((result) => {
      if (result.isConfirmed) {
        postRequest(`order/cancel/session/${id}/${sessionNo}`, {
          comment: "Session was canceled by Staff",
        })
          .then((res) => {
            setOrderDetails((prev) => ({
              ...prev,
              sessionList: res.data,
            }));
            tosterMessage("Session canceled successfully!", "success");
            window.location.reload();
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              text: "Failed Try Again!",
            });
          });
      }
    });
  };

  const handleCompleteSchedule = (sessionNo) => {
    Swal.fire({
      title: "Are you sure you want to mark this session as complete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, Complete Session!",
      background: "#fffff",
      color: "black",
    }).then((result) => {
      if (result.isConfirmed) {
        postRequest(`order/complete/session/${id}/${sessionNo}`, {
          comment: "Session was mark completed by Staff",
        })
          .then((res) => {
            setOrderDetails((prev) => ({
              ...prev,
              sessionList: res.data,
            }));
            tosterMessage("Session completed!", "success");
            window.location.reload();
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              text: "Failed Try Again!",
            });
          });
      }
    });
  };

  const {
    _id,
    consumerName,
    consumerAge,
    consumerContact,
    locationArea,
    address,
    description,
    relationWithCustomer,
    serviceName,
    consumerSex,
  } = orderDetails;

  const handleConfirmReq = () => {
    let data = { onGoing: true, pending: false, requested: false };
    putRequest(`/order/${_id}`, data)
      .then((res) => {
        //ToasterMessage("Data updated successfully!", "success");
        setIsLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        tosterMessage("Error Try Again!", "error");
        setIsLoading(false);
      });
  };
  const handleConfirmOrder = () => {
    let data = { onGoing: false, complete: true };
    putRequest(`/order/${_id}`, data)
      .then((res) => {
        //ToasterMessage("Data updated successfully!", "success");
        setIsLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        tosterMessage("Error Try Again!", "error");
        setIsLoading(false);
      });
  };

  // Note Print
  // ***************************
  const handler = new TemplateHandler();
  async function getTemplate(templateName) {
    const request = await fetch(`${process.env.PUBLIC_URL}/${templateName}`);
    const defaultTemplate = await request.blob();
    return defaultTemplate;
  }

  function saveFile(filename, blob) {
    // see: https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link

    // get downloadable url from the blob
    const blobUrl = URL.createObjectURL(blob);

    // create temp link element
    let link = document.createElement("a");
    link.download = filename;
    link.href = blobUrl;

    // use the link to invoke a download
    document.body.appendChild(link);
    link.click();

    // remove the link
    setTimeout(() => {
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
      link = null;
    }, 0);
  }

  const handlePrint = async (data) => {
    const finalData = {
      customerName: consumerName,
      contact: consumerContact,
      orderNo: orderDetails.orderId,
      date: new Date().toJSON().slice(0, 10),
      serviceName: serviceName,
      sessionNo: data.sessionNo,
      sessionCost: data.sessionCost,
    };
    let templateFile = await getTemplate("invoice-template.docx");
    let doc = await handler.process(templateFile, finalData);
    await saveFile(`invoice.docx`, doc);
  };

  // Note Print
  // ***************************

  if (isFetching === true)
    return (
      <h2>
        Loading... <CircularProgress />
      </h2>
    );
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Order Manager</Typography>

        <Link to={`/ordered-services/update/${id}`}>
          <Button disabled={orderDetails.complete} variant="outlined">
            Update
          </Button>
        </Link>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ px: 5, py: 3, mt: 2 }}>
            <Typography gutterBottom variant="h5">
              Order Summery
            </Typography>
            <Divider />
            <Box
              sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ width: "25%" }}>
                <Typography gutterBottom variant="h6">
                  Service Name: <b>{serviceName}</b>
                </Typography>
                <Typography gutterBottom variant="h6">
                  Order Status:{" "}
                  {orderDetails.requested && (
                    <Chip
                      label="REQUESTED"
                      color="warning"
                      variant="contained"
                    />
                  )}
                  {orderDetails.onGoing && (
                    <Chip label="ONGOING" color="primary" variant="contained" />
                  )}
                  {orderDetails.complete && (
                    <Chip
                      label="COMPLETE"
                      color="success"
                      variant="contained"
                    />
                  )}
                </Typography>
              </Box>
              <Box sx={{ width: "23%", px: 1 }}>
                <Typography gutterBottom variant="h6">
                  Customer Name: <b>{consumerName}</b>
                </Typography>
                <Typography gutterBottom variant="h6">
                  Phone Number: <b>{consumerContact}</b>
                </Typography>
                {orderDetails.requested && (
                  <Typography gutterBottom variant="h6">
                    Appointment Date:{" "}
                    <b>
                      {moment(
                        orderDetails?.comments[0]?.tentativeDate.split(" ")[0]
                      ).format("DD MMM, YYYY")}{" "}
                    </b>
                    Time
                    <b>
                      {orderDetails?.comments[0]?.tentativeDate.split("T")[1]}{" "}
                    </b>
                  </Typography>
                )}
              </Box>
              <Box sx={{ width: "35%" }}>
                <Typography gutterBottom variant="h6">
                  Customer Note
                </Typography>
                <Typography gutterBottom variant="body1">
                  {orderDetails?.comments[0]?.customerNote}
                </Typography>
              </Box>
              <Box sx={{ width: "15%" }}>
                {orderDetails.requested && (
                  <Button
                    variant="contained"
                    size=""
                    sx={{ float: "right", mt: 3 }}
                    onClick={handleConfirmReq}
                    disabled={isLoading}
                  >
                    Confirm order request
                  </Button>
                )}
                {orderDetails.onGoing && (
                  <Button
                    variant="contained"
                    size=""
                    sx={{ float: "right", mt: 3 }}
                    onClick={handleConfirmOrder}
                    disabled={isLoading}
                    color="success"
                  >
                    Complete Order
                  </Button>
                )}
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card sx={{ px: 5, py: 3, mt: 2 }}>
            <Typography gutterBottom variant="h5">
              Session List
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>SL</TableCell>
                        <TableCell align="left">Start Date & Time</TableCell>
                        <TableCell align="left">End Date</TableCell>
                        <TableCell align="center">Cost</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Payment Status</TableCell>
                        <TableCell align="center">Remarks</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderDetails.sessionList.map((row, index) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="left">
                            {new Date(row.startDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                            - {row.startTime}
                            {/*     {row.startDate} - {row.startTime} */}
                          </TableCell>
                          <TableCell align="left">
                            {new Date(row.endDate).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                            -{row.endTime}
                            {/*  {row.endDate} - {row.endTime} */}
                          </TableCell>
                          <TableCell align="center">
                            {row.sessionCost}
                          </TableCell>
                          <TableCell align="center">
                            {row.status
                              ? row.complete
                                ? "Completed"
                                : "Active"
                              : "Canceled"}
                          </TableCell>
                          <TableCell align="center">
                            {row.paid ? "Paid" : "Unpaid"}
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{row.comment}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <>
                              <Tooltip title="Cancel Schedule">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() =>
                                    handleCancelSchedule(index + 1)
                                  }
                                  disabled={!row.active}
                                  color="error"
                                >
                                  <EventBusyIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Mark Session complete">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() =>
                                    handleCompleteSchedule(index + 1)
                                  }
                                  disabled={!row.active}
                                  color="primary"
                                >
                                  <AssignmentTurnedInOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Download Invoice">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => handlePrint(row)}
                                  color="primary"
                                  disabled={!row.paid}
                                >
                                  <SimCardDownloadOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            </Box>
            <Button
              variant="outlined"
              size="small"
              sx={{ float: "right", mt: 3 }}
              onClick={handleClickOpen}
              disabled={orderDetails.complete}
            >
              ADD NEW SESSION
            </Button>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          {/* 
              first card */}

          <Card sx={{ p: 3 }}>
            <Box>
              <Typography gutterBottom variant="h5">
                Customer Details
              </Typography>
              <Box>
                <Stack>
                  {/* <Typography variant="text">Name</Typography> */}
                  <Typography variant="text" gutterBottom>
                    Name: {consumerName}
                    {/* ({consumerAge} Years) */}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="text" gutterBottom>
                    Address: {address}, {locationArea}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="text" gutterBottom>
                    Gender: {consumerSex}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="text" gutterBottom>
                    Phone Number: {consumerContact}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack>
              <Typography variant="h5">Description</Typography>
              <Typography variant="text" gutterBottom>
                {description}
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Typography gutterBottom variant="h5">
              Provider Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Stack>
                  <Typography variant="text" gutterBottom>
                    Provider Name:{" "}
                    <Typography variant="text">
                      {orderDetails.serviceProvider.serviceProviderName} (
                      {orderDetails.serviceProvider.serviceProviderCode})
                    </Typography>
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="text" gutterBottom>
                    Agent Name:{" "}
                    <Typography variant="text">
                      {orderDetails.serviceProvider.agentFullName}
                    </Typography>
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="text" gutterBottom>
                    Agent Address:{" "}
                    <Typography variant="text">
                      {orderDetails.serviceProvider.address}
                    </Typography>
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="text" gutterBottom>
                    Agent Contact:{" "}
                    <Typography variant="text">
                      {orderDetails.serviceProvider.contact}
                    </Typography>
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <AddSessionModal
        handleClose={handleClose}
        open={open}
        setOrderDetails={setOrderDetails}
        orderId={_id}
      />
    </>
  );
};

export default OrderDetails;
