import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const ServiceDetails = ({ order, handleBookingChange, serviceList }) => {
  return (
    <div>
      <Card
        sx={{ px: 5, py: 3, boxShadow: 0, border: 1, borderColor: "lightgray" }}
      >
        <Typography gutterBottom sx={{ typography: { xs: "h6", md: "h5" } }}>
          Service Details
        </Typography>

        <FormControl
          sx={{
            backgroundColor: "#F8FDFE",
            width: { md: "100%", xs: "100%" },
          }}
          size="small"
        >
          <InputLabel id="demo-simple-select-label">Services</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label="Services"
            variant="outlined"
            required
            onChange={handleBookingChange}
            name="serviceName"
            value={order.serviceName}
            size="small"
          >
            {serviceList.map((data) => (
              <MenuItem key={data._id} value={data}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Card>
    </div>
  );
};

export default ServiceDetails;
