import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import PostAddIcon from "@mui/icons-material/PostAdd";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { Link } from "react-router-dom";

const EmptyCustomerData = ({ colSpan, btnTitle, path }) => {
  return (
    <>
      <TableRow>
        <TableCell align="center" colSpan={colSpan}>
          <Box sx={{ textAlign: "center", width: "100%", py: 10 }}>
            <PostAddIcon color="primary" sx={{ fontSize: "150px" }} />
            <h2>No Order is Available</h2>
           
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EmptyCustomerData;
