import React from "react";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
const Note = ({ handleBookingChange, description }) => {
  return (
    <>
      <Card
        sx={{
          px: 5,
          py: 3,
          boxShadow: 0,
          border: 1,
          borderColor: "lightgray",
          mb: 3,
        }}
      >
        <Typography gutterBottom sx={{ typography: { xs: "h6", md: "h5" } }}>
          Description
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Description"
          multiline
          rows={5}
          onChange={handleBookingChange}
          name="description"
          defaultValue={description}
          sx={{ width: "100%" }}
          size="small"
        />
      </Card>
    </>
  );
};

export default Note;
