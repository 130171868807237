import http from "./http-common";
import { useAuth } from "../authProvider/AuthContextProvider";
import { useState } from "react";

const useGet = () => {
  const { token } = useAuth();
  const [data, setData] = useState(null);

  const getRequest = (url) => {
    return new Promise((resolve, reject) => {
      http
        .get(url, {
          headers: {
            Authorization: `Avantfutura ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return [getRequest];
};

export default useGet;