import React from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { useAuth } from "../../authProvider/AuthContextProvider";

const DashboardView = () => {
  const { user } = useAuth();
  const data = [
    {
      _id: 1,
      color: "#F1536E",
      path: "/ordered-services",
      name: "Ordered Services",
    },
    {
      _id: 2,
      color: "#3DA5F4",
      path: "/schedule/create",
      name: "Create Schedule",
    },
    {
      _id: 3,
      color: "#00C689",
      path: "/customer",
      name: "Customers",
    },
    {
      _id: 4,
      color: "#FDA006",
      path: "/requested-services",
      name: "Requested Services",
    },
  ];
  return (
    <>
      <Box>
        <h1>Hello there, {user.email}</h1>
        {/* <Typography>
          Here is some information we gathered about your website
        </Typography> */}
      </Box>

      <Grid container spacing={5} sx={{ mt: 5 }}>
        {data.map((val) => (
          <Grid item xs={12} md={3} key={val._id}>
            <Link to={val.path}>
              <Card
                sx={{ width: "100%", height: 150, backgroundColor: val.color }}
              >
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ mt: 5, color: "white" }}
                  >
                    {val.name}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DashboardView;
