import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";

const ConsumerDetails = ({
  order,
  handleBookingChange,
  locationData,
  forMe,
  consumerName,
  consumerAge,
  consumerContact,
  address,
  relationWithCustomer,
  locationArea,
  consumerSex,
  children,
  isCustomerExist,
}) => {
  return (
    <>
      <Card
        sx={{ px: 5, py: 3, boxShadow: 0, border: 1, borderColor: "lightgray" }}
        disabled={isCustomerExist}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography gutterBottom sx={{ typography: { xs: "h6", md: "h5" } }}>
            Consumer Details
          </Typography>
          {children}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* 
                  using || '' cause value overlape with label  */}
          {!forMe ? (
            <TextField
              variant="outlined"
              label="Customer Name"
              onChange={handleBookingChange}
              name="consumerName"
              defaultValue={consumerName || ""}
              /* value={order.consumerName || ""}  */
              sx={{ width: "100%", mr: 2 }}
              size="small"
              required
            />
          ) : (
            <TextField
              variant="outlined"
              label="Customer Name"
              onChange={handleBookingChange}
              name="consumerName"
              InputLabelProps={{ shrink: true }}
              value={order.consumerName}
              sx={{ width: "100%", mr: 2 }}
              size="small"
            />
          )}

          <TextField
            variant="outlined"
            label="Customer Age"
            placeholder="Customer Age"
            onChange={handleBookingChange}
            name="consumerAge"
            defaultValue={consumerAge || ""}
            /*  value={order.consumerAge} */
            sx={{ width: "100%", mb: 2 }}
            size="small"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!forMe ? (
            <TextField
              variant="outlined"
              label="Customer Contact"
              placeholder="Customer Contact"
              onChange={handleBookingChange}
              name="consumerContact"
              defaultValue={consumerContact}
              /* value={order.consumerContact || ""} */
              sx={{ width: "100%", mb: 2, mr: 2 }}
              size="small"
              required
            />
          ) : (
            <TextField
              variant="outlined"
              label="Customer Contact"
              placeholder="Customer Contact"
              onChange={handleBookingChange}
              InputLabelProps={{ shrink: true }}
              name="consumerContact"
              /* defaultValue={consumerContact} */
              value={order.consumerContact || ""}
              sx={{ width: "100%", mb: 2, mr: 2 }}
              size="small"
              required
            />
          )}

          {!forMe ? (
            <TextField
              variant="outlined"
              label="Address"
              onChange={handleBookingChange}
              name="address"
              defaultValue={address}
              /* value={order.address || ""} */
              sx={{ width: "100%" }}
              size="small"
              required
            />
          ) : (
            <TextField
              variant="outlined"
              label="Address"
              onChange={handleBookingChange}
              name="address"
              InputLabelProps={{ shrink: true }}
              /* defaultValue={address} */
              value={order.address || ""}
              sx={{ width: "100%" }}
              size="small"
              required
            />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            variant="outlined"
            label="Relation With Customer"
            placeholder="Relation With Customer"
            onChange={handleBookingChange}
            name="relationWithCustomer"
            defaultValue={relationWithCustomer}
            /* value={order.relationWithCustomer} */
            sx={{ width: "100%", mb: 2, mr: 2 }}
            size="small"
            disabled={forMe}
          />
          <TextField
            variant="outlined"
            label="Gender"
            placeholder="Gender"
            onChange={handleBookingChange}
            name="relationWithCustomer"
            defaultValue={consumerSex}
            sx={{ width: "100%" }}
            size="small"
            disabled={forMe}
          />
        </Box>

        <FormControl
          sx={{
            backgroundColor: "#F8FDFE",
            width: { md: "100%", xs: "100%" },
          }}
          size="small"
        >
          <InputLabel id="demo-simple-select-label">Location</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label="Location"
            onChange={handleBookingChange}
            name="locationArea"
            defaultValue={locationArea}
            /* value={order.locationArea} */
            sx={{ color: "black" }}
            size="small"
          >
            {locationData.map((data) => (
              <MenuItem key={data.name} value={data.name}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Card>
    </>
  );
};

export default ConsumerDetails;
