import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";

import { Link } from "react-router-dom";

const CreateCta = ({ path, title, btnName, ctaTitle }) => {
  return (
    <>
      <h1>{title}</h1>
      <Card sx={{ maxWidth: 345, mb: 5 }}>
        <CardActionArea>
          <Link to={path} style={{ color: "black" }}>
            <CardContent sx={{ textAlign: "center" }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <AddCircleOutlineIcon />
              </IconButton>
              <Typography gutterBottom variant="h6" component="div">
                {ctaTitle}
              </Typography>
            </CardContent>
          </Link>
        </CardActionArea>
        <Divider />
        <CardActions>
          <Button size="small" color="primary" as={Link} to={path}>
            {btnName}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default CreateCta;
