import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";

import useRequest from "../../apiServices/useRequest";
import ListCta from "../../components/common/ListCta";
import ToasterMessage from "../../components/common/ToasterMessage";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const intData = {
  metaTitle: "",
  metaDescription: "",
  title: "",
  bnTitle: "",
  description: "",
  bnDescription: "",
  thumImage: "",
  image: "",
  isActive: "active",
};
let intPreviewdata = { imgPreview: "", thumPreview: "" };

const Create = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(intData);
  const [preview, setPreview] = useState(intPreviewdata);

  // let today = new Date();
  // let days = 86400000;

  // const [fromDate, setFormDate] = useState(
  //   new Date(today + 1 * days).toJSON().slice(0, 10)
  // );

  const [image, setImage] = useState([]);

  const [thumImage, setThumImage] = useState([]);
  const [postRequest] = useRequest();

  const editorConfig = {
    placeholder: "Type your description in bangla...",
  };

  const editorConfig1 = {
    placeholder: "Type your description in english...",
  };
  const editorConfig2 = {
    placeholder: "Type your meta description here...",
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (fileExtension === "webp") {
        const files = [...image];
        files.push(...event.target.files);
        setImage([...files]);
        setPreview((pre) => ({
          ...pre,
          imgPreview: URL.createObjectURL(event.target.files[0]),
        }));
      } else {
        ToasterMessage("Please select a webp image file.", "error");
        event.target.value = null;
      }
    }
  };

  const handleThumImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension === "webp") {
        const files = [...thumImage];
        files.push(...event.target.files);
        setThumImage([...files]);
        setPreview((pre) => ({
          ...pre,
          thumPreview: URL.createObjectURL(event.target.files[0]),
        }));
      } else {
        ToasterMessage("Please select a webp image file.", "error");
        event.target.value = null;
      }
    }
  };
  const handleSubmit = () => {
    // const finalData = {
    //   ...data
    // };
    console.log(data);
    if (
      data.title !== "" &&
      data.bnTitle !== "" &&
      data.description !== "" &&
      data.bnDescription !== "" &&
      image !== "" &&
      thumImage !== "" &&
      data.isActive !== ""
    ) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("title", data.title);
      formData.append("bnTitle", data.bnTitle);
      formData.append("metaTitle", data.metaTitle);
      formData.append("description", data.description);
      formData.append("metaDescription", data.metaDescription);
      formData.append("bnDescription", data.bnDescription);
      image.forEach((ele) => {
        formData.append("image", ele);
      });
      thumImage.forEach((ele) => {
        formData.append("thumImage", ele);
      });
      formData.append("isActive", data.isActive);
      postRequest("blog", formData)
        .then((res) => {
          ToasterMessage("Data saved successfully!", "success");
          setIsLoading(false);
          navigate("/blog");
        })
        .catch((err) => {
          // console.log(err.response.data.message)
          ToasterMessage(err.response.data.message, "error");
          setData(intData);
          setImage([]);
          setThumImage([]);
          setPreview(intPreviewdata);
          setIsLoading(false);
        });
    } else {
      ToasterMessage("Please fill up all the required fields", "warning");
    }
  };

  if (isLoading === true)
    return (
      <h2>
        Loading... <CircularProgress />
      </h2>
    );

  return (
    <>
      <ListCta path="/blog" title="Blog Create" btnName="Blog List" />
      <Container>
        <Grid container>
          <Grid item xs={12} md={10} sx={{ m: "auto" }}>
            <Card sx={{ px: 5, py: 3 }}>
              <Box>
                <Typography variant="body2" display="block">
                  English Title<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  variant="outlined"
                  // label="English Title"
                  placeholder="English Title"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  onChange={(e) =>
                    setData((pre) => ({ ...pre, title: e.target.value }))
                  }
                  required
                />
              </Box>

              <Box>
                <Typography variant="body2" display="block">
                  Bangla Title<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder="Bangla Title"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  onChange={(e) =>
                    setData((pre) => ({ ...pre, bnTitle: e.target.value }))
                  }
                  required
                />
              </Box>
              {/* 
              <Box>
                <Typography variant="caption" display="block">
                  Blog code
                </Typography>
                <TextField
                  variant="outlined"
                  I
                  placeholder="Blog Code"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  onChange={(e) =>
                    setData((pre) => ({ ...pre, blogCode: e.target.value }))
                  }
                  required
                />
              </Box> */}
              <Box>
                <Typography variant="body2" display="block">
                  Meta Title<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  variant="outlined"
                  // label="English Title"
                  placeholder="Meta Title"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  onChange={(e) =>
                    setData((pre) => ({ ...pre, metaTitle: e.target.value }))
                  }
                  required
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" display="block">
                  Meta Description<span style={{ color: "red" }}>*</span>
                </Typography>
                {/* 
                <CKEditor
                  editor={ClassicEditor}
                  data={data.metaDescription}
                  config={editorConfig2}
                  onChange={(e, editor) =>
                    setData((pre) => ({
                      ...pre,
                      metaDescription: editor.getData(),
                    }))
                  }
                /> */}

                <TextField
                  variant="outlined"
                  // label="English Title"
                  placeholder="Meta Description"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  onChange={(e) =>
                    setData((pre) => ({ ...pre, metaDescription: e.target.value }))
                  }
                  multiline
                  rows={4}
                  required
                />
              </Box>
              <Box>
                <Typography variant="body2" display="block">
                  English Description<span style={{ color: "red" }}>*</span>
                </Typography>

                <CKEditor
                  editor={ClassicEditor}
                  data={data.description}
                  config={editorConfig1}
                  onChange={(e, editor) =>
                    setData((pre) => ({
                      ...pre,
                      description: editor.getData(),
                    }))
                  }
                />
              </Box>

              <Box sx={{ mt: 2, mb: 1 }}>
                <Typography variant="body2" display="block">
                  Bangla Description<span style={{ color: "red" }}>*</span>
                </Typography>
                <CKEditor
                  editor={ClassicEditor}
                  data={data.bnDescription}
                  config={editorConfig}
                  onChange={(e, editor) =>
                    setData((pre) => ({
                      ...pre,
                      bnDescription: editor.getData(),
                    }))
                  }
                />
              </Box>

              {/* <TextField
                  variant="outlined"
                  label="Description"
                  placeholder="Description"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  {...register("description")}
                /> */}
              {/* <Box>
                <Typography variant="caption" display="block">
                  Date
                </Typography>

                <TextField
                  type="date"
                  sx={{ width: "100%", mb: 2 }}
                  value={fromDate}
                  size="small"
                  onChange={(e) => setFormDate(e.target.value)}
                />
              </Box> */}
              <div style={{ display: "flex", padding: "3px" }}>
                {preview.thumPreview && (
                  <img
                    src={preview.thumPreview}
                    size="small"
                    width="300px"
                    style={{ padding: "3px" }}
                    alt="BRRI"
                  />
                )}
              </div>
              <Box>
                <Typography variant="body2" display="block">
                  Thumbnail Image<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  variant="outlined"
                  name="ThumImage"
                  onChange={handleThumImageChange}
                  type="file"
                  size="small"
                  sx={{ width: "100%" }}
                />
              </Box>
              <Typography
                variant="body1"
                sx={{ color: "#FF0000", fontWeight: "900" }}
              >
                * provide a compressed image in the highly efficient WebP
                format, ensuring it doesn't exceed a size of 500 kb, and format
                it to a visually appealing 500x180 pixels resolution.
              </Typography>
              <div style={{ display: "flex", padding: "3px" }}>
                {preview.imgPreview && (
                  <img
                    src={preview.imgPreview}
                    size="small"
                    width="300px"
                    style={{ padding: "3px" }}
                    alt="no chinta"
                  />
                )}
              </div>
              <Box>
                <Typography variant="body2" display="block">
                  Image<span style={{ color: "red" }}>*</span>
                </Typography>

                <TextField
                  variant="outlined"
                  name="Image"
                  onChange={handleImageChange}
                  type="file"
                  size="small"
                  sx={{ width: "100%" }}
                />
              </Box>
              <Typography
                variant="body1"
                sx={{ color: "#FF0000", fontWeight: "900" }}
              >
                * provide a compressed image in the highly efficient WebP
                format, ensuring it doesn't exceed a size of 1 MB, and format it
                to a visually appealing 1280x380 pixels resolution.
              </Typography>

              {/* <TextField
                  variant="outlined"
                  label="IsFeatured"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  {...register("isFeatured")}
                /> */}

              {/*  <TextField
                  variant="outlined"
                  label="Is Active"
            
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  {...register("isActive")}
                /> */}
              {/* <FormControlLabel
                label="Is Active"
                control={
                  <Switch
                    value={data.isActive}
                    checked={data.isActive===true}
                    onChange={(e) =>
                      setData((pre) => ({
                        ...pre,
                        isActive: e.target.checked,
                      }))
                    }
                  />
                }
              ></FormControlLabel> */}
              <Box sx={{ mt: 1 }}>
                <Typography variant="body2" display="block">
                  Is Active<span style={{ color: "red" }}>*</span>
                </Typography>
                <FormControl
                  sx={{
                    width: "100%",
                    mb: 2,
                  }}
                  size="small"
                >
                  <Select
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    name="isActive"
                    value={data.isActive}
                    onChange={(e) =>
                      setData((pre) => ({ ...pre, isActive: e.target.value }))
                    }
                    size="small"
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ mb: 2 }}>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  sx={{
                    fontWeight: "600",
                    width: "100%",
                  }}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Create;
