import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { useForm } from "react-hook-form";

import useRequest from "../../apiServices/useRequest";
import ListCta from "../../components/common/ListCta";
import ToasterMessage from "../../components/common/ToasterMessage";

const Create = () => {
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/rawData/serviceData.json`)
      .then((res) => res.json())
      .then((data) => {
        setServiceData(data);
      });
  }, []);

  const [locationData, setLocationData] = React.useState([]);

  React.useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/rawData/locationData.json`)
      .then((res) => res.json())
      .then((data) => {
        setLocationData(data);
      });
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(new Date());

  const { register, handleSubmit, reset } = useForm();
  const [postRequest] = useRequest();
  const onSubmit = async (data) => {
    setIsLoading(true);

    const finalData = {
      ...data,
      date: value.toLocaleDateString(),
      time: value.toLocaleTimeString(),
    };

    reset();
    postRequest("schedule", finalData)
      .then((res) => {
        ToasterMessage("Data saved successfully!", "success");
        setIsLoading(false);
      })
      .catch((err) => {
        ToasterMessage("Error Try Again!", "error");
        setIsLoading(false);
      });
  };

  return (
    <>
      <ListCta
        path="/provider"
        title="Provider Create"
        btnName="Provider List"
      />
      <Container>
        <Grid container>
          <Grid item xs={12} md={10} sx={{ m: "auto" }}>
            <Card sx={{ px: 5, py: 3 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Autocomplete
                  autoHighlight
                  id="combo-box-demo"
                  options={serviceData}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: "100%", mb: 2 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Services"
                      size="small"
                      required
                      {...register("service")}
                    />
                  )}
                />

                <TextField
                  variant="outlined"
                  label="Name"
                  placeholder="Name"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  {...register("name")}
                  required
                />

                <TextField
                  variant="outlined"
                  label="Phone"
                  placeholder="+8801700000000"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  {...register("phone", { required: true, maxLength: 11 })}
                  required
                />

                <TextField
                  variant="outlined"
                  label="Email"
                  placeholder="email@example.com"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  {...register("email")}
                />

                <Autocomplete
                  disablePortal
                  id="combo-box"
                  options={locationData}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: "100%", mb: 2 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      size="small"
                      required
                      {...register("location")}
                    />
                  )}
                />

                <TextField
                  variant="outlined"
                  label="Address"
                  placeholder="Your address"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  {...register("address")}
                  required
                />
                {/* 
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...register("date")}
                        label="Schedule Date"
                        sx={{ width: "100%", mb: 2 }}
                        size="small"
                      />
                    )}
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    minDateTime={new Date()}
                  />
                </LocalizationProvider>
 */}
                <Box sx={{ mb: 2 }}>
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    sx={{
                      fontWeight: "600",
                      width: "100%",
                    }}
                    type="submit"
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Create;
