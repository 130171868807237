import * as React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../authProvider/AuthContextProvider";
import { Button } from "@mui/material";

export default function Navbar({ handleDrawerToggle, drawerWidth }) {
  const location = useLocation();
  const pathArray = location.pathname.split("/");
  const { logout, user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        bgcolor: "text.primary",
        backgroundColor: "#F7FCFF",
        color: "black",
        boxShadow: 0,
        borderBottom: 1,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderStyle: "dashed",
        borderColor: "lightgray",
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        {pathArray.length > 2 ? (
          <Typography
            variant="button"
            component="div"
            sx={{ flexGrow: 1, fontWeight: 600 }}
          >
            {pathArray[1]} / {pathArray[2]}
          </Typography>
        ) : (
          <Typography
            variant="button"
            component="div"
            sx={{ flexGrow: 1, fontWeight: 600 }}
          >
            {pathArray[1]}
          </Typography>
        )}

        <Box>
        {user.email}
          <Tooltip title={user.email} arrow>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{ mt: 5 }}
          >
            <MenuItem>
              <Button onClick={logout}>
                <LogoutIcon sx={{ mr: 1 }} />
                Log Out
              </Button>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
