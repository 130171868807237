import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

/*****************
   Auth Provider 
******************/
import AuthContextProvider from "./authProvider/AuthContextProvider";

import LoginPage from "./pages/loginPage/LoginPage";
import DashboardLayout from "./layout/DashboardLayout";
import Dashboard from "./pages/dashboard/Dashboard";
/* import Schedule from "./pages/Schedule/Schedule"; */
import ScheduleCreate from "./pages/scheduleService/Create";
import CustomerList from "./pages/customerService/List";
import CustomerCreate from "./pages/customerService/Create";
import ProviderList from "./pages/providerService/List";
import ProviderCreate from "./pages/providerService/Create";
import ScheduleUpdate from "./pages/scheduleService/Update";

import ScheduleSearch from "./pages/scheduleService/Search";

import CustomerUpdate from "./pages/customerService/Update";
import ProviderUpdate from "./pages/providerService/Update";
import RequestList from "./pages/requestedServices/List";
import OrderList from "./pages/orderServices/List";
import HistoryList from "./pages/serviceHistory/List";

import ServiceList from "./pages/services/List";
import ServiceCreate from "./pages/services/Create";
import ServiceUpdate from "./pages/services/Update";

import Registration from "./pages/registration/Registration";
import StaffList from "./pages/staffs/List";
import StaffCreate from "./pages/staffs/Create";
import AdminRoute from "./utilities/adminRoute";
import StaffRoute from "./utilities/staffRoute";
import InvitatonList from "./pages/invitation/List";
import OrderDetails from "./pages/orderDetails/OrderDetails";
import OrderUpdate from "./pages/orderServices/Update";

// for blog
import BlogList from "./pages/blogs/List";
import BlogCreate from "./pages/blogs/Create";
import BlogUpdate from "./pages/blogs/Update";

function App() {
  return (
    <AuthContextProvider>
      <Router>
        <Routes>
          <Route element={<LoginPage />} path="/" />
          <Route element={<Registration />} path="/signup" />
          <Route element={<DashboardLayout />}>
            {/*********************************                
                       Admin Routes Start 👇
                *********************************/}

            <Route element={<AdminRoute />}>
              {/* service route */}
              {/* blog information */}
              <Route element={<BlogList />} path="/blog" />
              <Route element={<BlogCreate />} path="/blog/create" />
              <Route element={<BlogUpdate />} path="/blog/update/:id" />
              <Route element={<ServiceList />} path="/service" />
              <Route element={<ServiceCreate />} path="/service/create" />
              <Route element={<ServiceUpdate />} path="/service/update/:id" />

              {/* Staff information */}
              <Route element={<StaffList />} path="/staff/list" />
              <Route element={<StaffCreate />} path="/staff/create" />
              <Route element={<InvitatonList />} path="/invitations" />
            </Route>
            {/*********************************           
                      Admin Routes End 👆
                *********************************/}

            {/*********************************                
                       Staff Routes Start 👇
                *********************************/}
            <Route element={<StaffRoute />}>
              <Route element={<Dashboard />} path="/dashboard" />

              <Route element={<ScheduleCreate />} path="/schedule/create" />
              <Route element={<ScheduleSearch />} path="/schedule/search" />

              <Route
                element={<ScheduleUpdate />}
                path="/schedule/update/:name"
              />

              <Route element={<RequestList />} path="/requested-services" />
              <Route element={<OrderList />} path="/ordered-services" />
              <Route
                element={<OrderUpdate />}
                path="/ordered-services/update/:id"
              />

              <Route
                element={<OrderDetails />}
                path="/ordered-services/detail/:id"
              />
              <Route element={<HistoryList />} path="/service-history" />

              <Route element={<CustomerList />} path="/customer" />
              <Route element={<CustomerCreate />} path="/customer/create" />
              <Route element={<CustomerUpdate />} path="/customer/update/:id" />

              <Route element={<ProviderList />} path="/provider" />
              <Route element={<ProviderCreate />} path="/provider/create" />
              <Route
                element={<ProviderUpdate />}
                path="/provider/update/:name"
              />
            </Route>
            {/*********************************           
                      Admin Routes End 👆
                *********************************/}
          </Route>
        </Routes>
      </Router>
    </AuthContextProvider>
  );
}

export default App;
