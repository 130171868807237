import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";

const SessionList = ({
  handleSessionDetailChange,
  value,
  endDate,
  sessionDetail,
  handleAddService,
  sessionList,
  setValue,
  setEndDate,
}) => {
  return (
    <>
      <Card
        sx={{
          px: 5,
          py: 3,
          boxShadow: 0,
          border: 1,
          borderColor: "lightgray",
          my: 3,
        }}
      >
        <Typography gutterBottom sx={{ typography: { xs: "h6", md: "h5" } }}>
          Session List
        </Typography>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="caption">Start Date</Typography>
                  <TextField
                    type="date"
                    onChange={handleSessionDetailChange}
                    name="startDate"
                    value={sessionDetail?.startDate}
                    size="small"
                    sx={{ width: "90%" }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Typography variant="caption">End Date</Typography>
                  <TextField
                    type="date"
                    onChange={handleSessionDetailChange}
                    name="endDate"
                    value={sessionDetail?.endDate}
                    size="small"
                    sx={{ width: "90%" }}
                  />
                </TableCell>

                <TableCell align="left">
                  <Typography variant="caption">Start Time</Typography>
                  <TextField
                    type="time"
                    onChange={handleSessionDetailChange}
                    name="startTime"
                    value={sessionDetail?.startTime}
                    size="small"
                    sx={{ width: "90%" }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="caption">End Time</Typography>
                  <TextField
                    type="time"
                    onChange={handleSessionDetailChange}
                    name="endTime"
                    value={sessionDetail?.endTime}
                    size="small"
                    sx={{ width: "90%" }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Typography variant="caption">Session Cost</Typography>
                  <TextField
                    onChange={handleSessionDetailChange}
                    name="sessionCost"
                    value={sessionDetail?.sessionCost}
                    size="small"
                    sx={{ width: "90%" }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Typography variant="caption">Comments</Typography>
                  <TextField
                    onChange={handleSessionDetailChange}
                    name="comment"
                    value={sessionDetail?.comment}
                    size="small"
                    sx={{ width: "90%" }}
                  />
                </TableCell>

                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => handleAddService(sessionDetail)}
                    sx={{ mt: 3 }}
                  >
                    ADD
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>SL</TableCell>
                <TableCell align="left">START DATE</TableCell>
                <TableCell align="left">END DATE</TableCell>
                <TableCell align="left">COST</TableCell>
                <TableCell align="left">COMMENT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessionList.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="left">
                    {row.startDate} - {row.startTime}
                  </TableCell>
                  <TableCell align="left">
                    {row.endDate} - {row.endTime}
                  </TableCell>
                  <TableCell align="left">{row.sessionCost}</TableCell>
                  <TableCell align="left">{row.comment}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default SessionList;
