import React, { useState } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import PostAddIcon from "@mui/icons-material/PostAdd";

import useGetRequest from "../../apiServices/useGetRequest";

import { Link } from "react-router-dom";

import Button from "@mui/material/Button";

import CreateCustomer from "../../components/common/CreateCustomer";

const List = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isFetching, dataList, setDataList] = useGetRequest(`/customer`);
  const [copyList, setCopyList] = useState(dataList);

  const requestSearch = (searched) => {
    setCopyList(
      dataList.filter((item) => item.primaryContact.includes(searched))
    );
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataList.length) : 0;

  if (isFetching)
    return (
      <h2>
        Loading... <CircularProgress />
      </h2>
    );

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 5 }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Customer List
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            List of all the customers registered with NoChinta Ltd.
          </Typography>
        </Box>
        <Box>
          <Button variant="outlined" onClick={handleOpen}>
            Create new customer
          </Button>
        </Box>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ boxShadow: 0, border: 1, borderColor: "lightgray" }}
      >
        {/* <TextField
          variant="outlined"
          placeholder="Search with customer phone number..."
          type="search"
          onInput={(e) => requestSearch(e.target.value)}
          fullWidth
          size="small"
        /> */}
        <Table sx={{ minWidth: 100 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#F6F9FC" }}>
            <TableRow>
              <TableCell>Customer No</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Phone</TableCell>
              {/* <TableCell>Email</TableCell> */}
              <TableCell>Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.length === 0 ? (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <Box sx={{ textAlign: "center", width: "100%", py: 10 }}>
                    <PostAddIcon color="primary" sx={{ fontSize: "150px" }} />
                    <h2>No Data Available</h2>
                    <Box sx={{ mt: 3 }}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={handleOpen}
                      >
                        Create Customer
                      </Button>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              (copyList.length > 0 ? copyList : dataList)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    as={Link}
                    to={`/customer/update/${row._id}`}
                    state={{ data: row }}
                    hover
                  >
                    <TableCell scope="row">
                      <b>{page * rowsPerPage + index + 1}</b>
                    </TableCell>
                    <TableCell>{row.fullName}</TableCell>
                    <TableCell>{row.primaryContact}</TableCell>
                    {/* <TableCell>{row.emails[0]}</TableCell> */}

                    <TableCell>{row.address}</TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={copyList.length > 0 ? copyList.length : dataList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <CreateCustomer
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        setDataList={setDataList}
      />
    </>
  );
};

export default List;
