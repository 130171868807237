import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { useForm } from "react-hook-form";

import { Link, useLocation, useNavigate } from "react-router-dom";
import useRequest from "../../apiServices/useRequest";
import ListCta from "../../components/common/ListCta";
import ToasterMessage from "../../components/common/ToasterMessage";
import InputLabel from "@mui/material/InputLabel";

const Update = () => {
  const [serviceData, setServiceData] = useState([]);
  let location = useLocation();
  let navigate = useNavigate();
  const { _id, service, name, phone, email, address, date } = location.state;

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/rawData/serviceData.json`)
      .then((res) => res.json())
      .then((data) => {
        setServiceData(data);
      });
  }, []);

  /*   const [locationData, setLocationData] = React.useState([]);

  React.useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/rawData/locationData.json`)
      .then((res) => res.json())
      .then((data) => {
        setLocationData(data);
      });
  }, []); */

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(new Date());

  const { register, handleSubmit, reset } = useForm();
  const [postRequest, putRequest, deleteRequest] = useRequest();
  const onSubmit = async (data) => {
    setIsLoading(true);

    const finalData = {
      ...data,
      date: value.toLocaleDateString(),
      time: value.toLocaleTimeString(),
    };

    reset();
    await putRequest(`schedule/${_id}`, finalData)
      .then((res) => {
        ToasterMessage("Data updated successfully!", "success");
        setIsLoading(false);
      })
      .catch((err) => {
        ToasterMessage("Error Try Again!", "error");
        setIsLoading(false);
      });
    navigate("/schedule");
  };

  return (
    <>
      <ListCta
        path="/schedule"
        title="Schedule Update"
        btnName="Schedule List"
      />
      <Container>
        <Grid container>
          <Grid item xs={12} md={10} sx={{ m: "auto" }}>
            <Card sx={{ px: 5, py: 3 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                  sx={{
                    backgroundColor: "#F8FDFE",
                    width: "100%",
                    mb: 2,
                  }}
                >
                  {/* <InputLabel>Service</InputLabel> */}
                  <Select
                    required
                    {...register("service")}
                    defaultValue={service}
                  >
                    {serviceData.map((data) => (
                      <MenuItem key={data.name} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  variant="outlined"
                  label="Name"
                  placeholder="Name"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  {...register("name")}
                  defaultValue={name}
                  required
                />

                <TextField
                  variant="outlined"
                  label="Phone"
                  placeholder="+8801700000000"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  {...register("phone", { required: true, maxLength: 11 })}
                  defaultValue={phone}
                  required
                />

                <TextField
                  variant="outlined"
                  label="Email"
                  placeholder="email@example.com"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  {...register("email")}
                  defaultValue={email}
                />

                {/*   <Autocomplete
                  disablePortal
                  id="combo-box"
                  options={locationData}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: "100%", mb: 2 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      size="small"
                      defaultValue={location}
                      required
                      {...register("location")}
                    />
                  )}
                /> */}

                <TextField
                  variant="outlined"
                  label="Address"
                  placeholder="Your address"
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  {...register("address")}
                  defaultValue={address}
                  required
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...register("date")}
                        label="Schedule Date"
                        sx={{ width: "100%", mb: 2 }}
                        defaultValue={date}
                        size="small"
                      />
                    )}
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    minDateTime={new Date()}
                  />
                </LocalizationProvider>

                <Box sx={{ mb: 2 }}>
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    sx={{
                      fontWeight: "600",
                      width: "100%",
                    }}
                    type="submit"
                  >
                    Update
                  </LoadingButton>
                </Box>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Update;
