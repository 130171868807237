import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useRequest from "../../apiServices/useRequest";
import ToasterMessage from "../../components/common/ToasterMessage";

const Search = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [datalist, setDataList] = useState([]);
  const [postRequest] = useRequest();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    postRequest("customer/key", user)
      .then((res) => {
        ToasterMessage("Data saved successfully!", "success");
        setDataList(res.data);

        console.log("search data is", datalist);
        setIsLoading(false);
      })
      .catch((err) => {
        ToasterMessage("Error Try Again!", "error");

        setIsLoading(false);
      });
  };

  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            label="Search"
            placeholder="Search by Phone number"
            name="primaryContact"
            value={user.search}
            sx={{ width: "40%", mb: 2 }}
            size="small"
            onChange={handleChange}
            required
          />

          <Button type="submit" variant="outlined">
            Search
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default Search;
