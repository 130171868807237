import React, { useState } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import PostAddIcon from "@mui/icons-material/PostAdd";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";

import useGetRequest from "../../apiServices/useGetRequest";

import ToasterMessage from "../../components/common/ToasterMessage";
import Typography from "@mui/material/Typography";

import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_URL;

const List = () => {
  const [isFetching, dataList] = useGetRequest(`/staff`);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataList.length) : 0;

  const handleUpdate = (id, status) => {
    const data = status;
    if (data === false) {
      /*  getRequest(`staff/deactivate/${id}`, data) */
      axios.get(`${baseURL}/staff/deactivate/${id}`, data).then((res) => {
        /*  isFetching(
            dataList.map((item) =>
              item._id === id ? { ...item, status: status } : item
            )
          ); */
        //console.log("data is", res.data);
        ToasterMessage("Data updated successfully!", "success");
      });
    } else {
      /*  getRequest(`staff/activate/${id}`, data) */
      axios.get(`${baseURL}/staff/activate/${id}`, data).then((res) => {
        /* isFetching(
            dataList.map((item) =>
              item._id === id ? { ...item, status: status } : item
            )
          ); */

        //console.log("second data is", res.data);
        ToasterMessage("Data updated successfully!", "success");
      });
    }
  };
  if (isFetching)
    return (
      <h2>
        Loading... <CircularProgress />
      </h2>
    );

  return (
    <>
      {/* <CreateCta
        path="/staff/create"
        title="Staff List"
        ctaTitle="Create a Staff"
        btnName="Create Staff"
      /> */}
      <Box mt={2}>
        <Typography variant="h4">Staff List</Typography>
        <Typography mb={2} variant="body2" gutterBottom>
          All staff memeber of NoChinta
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 0, border: 1, borderColor: "lightgray" }}
        >
          <Table sx={{ minWidth: 100 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#F6F9FC" }}>
              <TableRow>
                <TableCell>SL</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Availability</TableCell>
                {/* <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell> */}
                <TableCell>Active Status</TableCell>

                {/* <TableCell align="center">Action</TableCell> */}
                {/*
                 */}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <Box sx={{ textAlign: "center", width: "100%", py: 10 }}>
                      <PostAddIcon color="primary" sx={{ fontSize: "150px" }} />
                      <h2>No Data Available</h2>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                dataList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <b>{index + 1}</b>
                      </TableCell>
                      <TableCell>{row.fullName}</TableCell>
                      <TableCell>{row.availability && "Available"}</TableCell>
                      {/* <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell> */}
                      <TableCell>
                        {" "}
                        <FormControlLabel
                          control={
                            <Switch
                              defaultChecked={row.active}
                              onChange={() =>
                                handleUpdate(row._id, !row.active)
                              }
                            />
                          }
                        />
                      </TableCell>

                      {/* <TableCell align="center">
                        <Button>Details</Button>
                      </TableCell> */}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <Divider />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </>
  );
};

export default List;
