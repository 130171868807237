import Swal from "sweetalert2";

const tosterMessage = (message, status) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
  return Toast.fire({
    icon: status,
    title: message,
  });
};

export default tosterMessage;
