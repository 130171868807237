import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import React, { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useGetRequest from "../../apiServices/useGetRequest";

import CreateCta from "../../components/common/CreateCta";
import EmptyTableData from "../../components/common/EmptyTableData";

const List = () => {
  const [isFetching, dataList] = useGetRequest(`/schedule`);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataList.length) : 0;

  if (isFetching)
    return (
      <h2>
        Loading... <CircularProgress />
      </h2>
    );

  return (
    <>
      <CreateCta
        path="/provider/create"
        title="Provider List"
        ctaTitle="Create a Provider"
        btnName="Create Provider"
      />
      <TableContainer
        component={Paper}
        sx={{ boxShadow: 0, border: 1, borderColor: "lightgray" }}
      >
        <Table sx={{ minWidth: 100 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#F6F9FC" }}>
            <TableRow>
              <TableCell>SL</TableCell>
              <TableCell>Service Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Address</TableCell>

              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.length === 0 ? (
              <EmptyTableData
                colSpan={8}
                btnTitle="Create Provider"
                path="/provider/create"
              />
            ) : (
              dataList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <b>{index + 1}</b>
                    </TableCell>
                    <TableCell>{row.service}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.address}</TableCell>

                    <TableCell align="center">
                      <Link state={row} to={`/provider/update/${row.name}`}>
                        <Button>
                          <EditIcon />
                        </Button>
                      </Link>
                      <Button
                        color="error" /* onClick={() => handleDelete(row._id)} */
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default List;
