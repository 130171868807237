import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";

import useRequest from "../../apiServices/useRequest";
import ListCta from "../../components/common/ListCta";
import ToasterMessage from "../../components/common/ToasterMessage";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import useGetRequest from "../../apiServices/useGetRequest";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Divider from "@mui/material/Divider";
import EmptyCustomerData from "../../components/common/EmptyCustomerData";

const orderList = [
  {
    idNo: 2569853,
    Date: "12/25/2022",
    Status: "Paid",
    serviceCode: "BB-21",
    serviceName: "Baby Sitting",
  },
  {
    idNo: 59866546,
    Date: "12/25/2022",
    Status: "Unpaid",
    serviceCode: "PHY-21",
    serviceName: "Physiotherapy",
  },
];

const Update = () => {
  const [isLoading, setIsLoading] = useState(false);
  let { id } = useParams();
  let location = useLocation();
  const customerDetail = location.state.data;
  const { fullName, emails, address, primaryContact, totalDue } =
    customerDetail;

  const [, putRequest] = useRequest();
  let navigate = useNavigate();
  const [isFetching, dataList, setDataList] = useGetRequest(
    `order/customer/${id}`
  );
  // const {
  //   _id,
  //   fullName,
  //   emails,
  //   primaryContact,
  //   activationDate,
  //   address,
  //   totalDue,
  // } = dataList;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataList.length) : 0;

  /*  const onSubmit = async (data) => {
    setIsLoading(true);
    reset();
    console.log(dataList);
    const finalData = {
      ...data,
      isFeatured: isFeatured,
      isActive: isActive,
    };
    await putRequest(`service/${_id}`, finalData)
      .then((res) => {
        ToasterMessage("Data updated successfully!", "success");
        setIsLoading(false);
        navigate("/service");
      })
      .catch((err) => {
        ToasterMessage("Error Try Again!", "error");
        setIsLoading(false);
      });
    navigate("/service");
  }; */

  // console.log("the expected data",cusdetail)
  if (isFetching === true)
    return (
      <h2>
        Loading... <CircularProgress />
      </h2>
    );
  return (
    <>
      <ListCta
        path="/customer"
        title="Customer Detail"
        btnName="Customer List"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Card sx={{ px: 5, py: 3 }}>
            <Box>
              <Stack>
                <Typography variant="text">Name</Typography>
                <Typography
                  variant="text"
                  sx={{ fontWeight: 600 }}
                  gutterBottom
                >
                  {fullName}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="text">Email</Typography>
                <Typography
                  variant="text"
                  sx={{ fontWeight: 600 }}
                  gutterBottom
                >
                  {emails[0]}
                </Typography>
              </Stack>

              <Stack>
                <Typography variant="text">Phone</Typography>
                <Typography
                  variant="text"
                  sx={{ fontWeight: 600 }}
                  gutterBottom
                >
                  {primaryContact}
                </Typography>
              </Stack>

              <Stack>
                <Typography variant="text">Address</Typography>
                <Typography
                  variant="text"
                  sx={{ fontWeight: 600 }}
                  gutterBottom
                >
                  {address}
                </Typography>
              </Stack>

              <Stack>
                <Typography variant="text">Total Due</Typography>
                <Typography
                  variant="text"
                  sx={{ fontWeight: 600 }}
                  gutterBottom
                >
                  {totalDue}
                </Typography>
              </Stack>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ px: 5, py: 3 }}>
            <Typography
              variant="text"
              sx={{ fontWeight: 600, fontSize: "30px" }}
              gutterBottom
            >
              Recent Orders
            </Typography>

            <TableContainer
              component={Paper}
              sx={{ boxShadow: 0, border: 1, borderColor: "lightgray" }}
            >
              <Table sx={{ minWidth: 100 }} aria-label="simple table">
                <TableHead sx={{ backgroundColor: "#F6F9FC" }}>
                  <TableRow>
                    <TableCell>Order No</TableCell>
                    <TableCell>Service Name</TableCell>
                    <TableCell>Order Id</TableCell>
                    <TableCell>Total Bill</TableCell>
                    <TableCell>Total Due</TableCell>
                    {/* <TableCell>Status</TableCell> */}
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataList.length === 0 ? (
                    <EmptyCustomerData
                      colSpan={8}
                      btnTitle="Create Customer"
                      path="/customer/create"
                    />
                  ) : (
                    dataList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          hover
                        >
                          <TableCell component="th" scope="row">
                            <b>{page * rowsPerPage + index + 1}</b>
                          </TableCell>
                          <TableCell>{row.serviceName}</TableCell>
                          <TableCell>{row.orderId}</TableCell>
                          <TableCell>{row.totalBill}</TableCell>
                          <TableCell>{row.totalDue}</TableCell>
                          {/* <TableCell>
                            <Stack spacing={2}>
                              {row.requested === true && (
                                <Chip
                                  label="REQUESTED"
                                  color="warning"
                                  variant="contained"
                                />
                              )}
                              {row.pending === true && (
                                <Chip
                                  label="PENDING"
                                  color="secondary"
                                  variant="contained"
                                />
                              )}
                              {row.onGoing === true && (
                                <Chip
                                  label="ONGOING"
                                  color="primary"
                                  variant="contained"
                                />
                              )}
                              {row.complete === true && (
                                <Chip
                                  label="COMPLETE"
                                  color="success"
                                  variant="contained"
                                />
                              )}
                            </Stack>
                          </TableCell> */}

                          <TableCell align="center">
                            <Button
                              as={Link}
                              to={`/ordered-services/detail/${row._id}`}
                              variant="outlined"
                            >
                              Details
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
              <Divider />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
      ``
    </>
  );
};

export default Update;
