import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import React, { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useGetRequest from "../../apiServices/useGetRequest";
import useRequest from "../../apiServices/useRequest";

//import CreateCta from "../../components/common/CreateCta";
import EmptyTableData from "../../components/common/EmptyTableData";
import Swal from "sweetalert2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Chip } from "@mui/material";

const List = () => {
  const [isFetching, dataList, setDataList] = useGetRequest(`/blog`);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [, , deleteRequest] = useRequest();

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRequest(`blog/${id}`)
          .then((res) => {
            Swal.fire({
              icon: "success",
              text: "Blog was deleted",
            });
            setDataList(dataList.filter((item) => item._id !== id));
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              text: "Failed Try Again!",
            });
          });
      }
    });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataList.length) : 0;
  if (isFetching)
    return (
      <h2>
        Loading... <CircularProgress />
      </h2>
    );

  return (
    <>
      <Box mt={2}>
        {/* <CreateCta
        path="/service/create"
        title="Service List"
        ctaTitle="Create a Service"
        btnName="Create Service"
      /> */}
        <Box sx={{ float: "right" }}>
          <Link to="/blog/create">
            <Button variant="contained">Create new blog</Button>
          </Link>
        </Box>
        <Typography variant="h4">Blog List</Typography>
        <Typography mb={2} variant="body2" gutterBottom>
          All blog list of NoChinta
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 0, border: 1, borderColor: "lightgray" }}
        >
          <Table sx={{ minWidth: 100 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#F6F9FC" }}>
              <TableRow>
                <TableCell align="center">SL</TableCell>
                <TableCell align="center">English Title</TableCell>
                <TableCell align="center">Bangla Title</TableCell>
                <TableCell align="center">Meta Title</TableCell>
                <TableCell align="center">Thumbnail Image</TableCell>
                <TableCell align="center">Blog Image</TableCell>
                <TableCell align="center">Active Status</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList.length === 0 ? (
                <EmptyTableData
                  colSpan={6}
                  btnTitle="Create Blog"
                  path="/blog/create"
                />
              ) : (
                dataList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        <b>{page * rowsPerPage + index + 1}</b>
                      </TableCell>
                      <TableCell align="center">{row.title}</TableCell>
                      <TableCell align="center">{row.bnTitle}</TableCell>
                      <TableCell align="center">{row.metaTitle}</TableCell>
                      <TableCell align="center">
                        <img
                          src={`${process.env.REACT_APP_BACKEND_URL}/images/blogThumImage/${row.thumImage}`}
                          alt="No Chinta"
                          width={300}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <img
                          src={`${process.env.REACT_APP_BACKEND_URL}/images/blogImage/${row.image}`}
                          alt="No Chinta"
                          width={300}
                        />
                      </TableCell>

                      <TableCell align="center">
                        {/* {row.isActive ? "Active" : "Inactive"} */}
                        <Chip
                          label={row.isActive}
                          color={
                            row.isActive === "active" ? "primary" : "warning"
                          }
                          variant="outlined"
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Link to={`/blog/update/${row._id}`}>
                          <Button>
                            <EditIcon />
                          </Button>
                        </Link>
                        <Button
                          color="error"
                          onClick={() => handleDelete(row._id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <Divider />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </>
  );
};

export default List;
