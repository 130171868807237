import { useState, useEffect } from "react";
import http from "../apiServices/http-common";

const loginUrl = "/auth/login";
const logoutUrl = "/auth/logout";
const userDetailsUrl = "/auth/user";

const getToken = () => {
  return localStorage.getItem("_token");
};

const useAuthDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const [authError, setAuthError] = useState("");

  const token = getToken();

  const loginUser = (email, password, navigate) => {
    setIsLoading(true);
    http
      .post(loginUrl, {
        email,
        password,
      })
      .then((res) => {
        localStorage.setItem("_token", res.data.accessToken);
        http
          .get(userDetailsUrl, {
            headers: {
              Authorization: `NoChinta ${res.data.accessToken}`,
            },
          })
          .then((res) => {
            setUser(res.data);
            if (res.data.roleCode === 1) {
              navigate("/staff/list");
            } else if (res.data.roleCode === 2) {
              navigate("/dashboard");
            }
            setIsLoading(false);
            setAuthError("");
          });
      })
      .catch((error) => {
        setIsLoading(false);
        setAuthError(error.response.data.message);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if (token) {
      http
        .get(userDetailsUrl, {
          headers: {
            Authorization: `NoChinta ${token}`,
          },
        })
        .then((res) => {
          setUser(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          // console.log(error);
          setIsLoading(false);
        });
    } else {
      setUser({});
      setIsLoading(false);
    }
  }, []);

  const logout = () => {
    setIsLoading(true);
    http
      .get(logoutUrl, {
        headers: {
          Authorization: `NoChinta ${token}`,
        },
      })
      .then((res) => {
        setUser({});
        localStorage.removeItem("_token");
      })
      .finally(() => setIsLoading(false));
  };

  return { loginUser, isLoading, token, user, logout, authError };
};

export default useAuthDetails;
