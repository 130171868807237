import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";

import { useForm } from "react-hook-form";

import useRequest from "../../apiServices/useRequest";
import ListCta from "../../components/common/ListCta";
import ToasterMessage from "../../components/common/ToasterMessage";
import { useNavigate, useParams } from "react-router-dom";
import { FormControlLabel, Switch } from "@mui/material";
import useGetRequest from "../../apiServices/useGetRequest";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Update = () => {
  const [isLoading, setIsLoading] = useState(false);
  let { id } = useParams();
  // const { register, handleSubmit, reset } = useForm();
  const [, putRequest] = useRequest();
  let navigate = useNavigate();
  const [image, setImage] = useState([]);
  const [preview, setPreview] = useState("");
  const [isFetching, data, setData] = useGetRequest(`/service/${id}`);
  const {
    _id,
    name,
    serviceCode,
    bnName,
    isFeatured,
    isActive,
    description,
    bnDescription,
  } = data;

  const editorConfig = {
    placeholder: "Type your description in bangla...",
  };

  const editorConfig1 = {
    placeholder: "Type your description in english...",
  };

  const handleImageChange = (event) => {
    const files = [...image];
    files.push(...event.target.files);
    setImage([...files]);
    setPreview(URL.createObjectURL(event.target.files[0]));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("bnName", data.bnName);
    formData.append("serviceCode", data.serviceCode);
    // if (data.image[0] !== undefined) {
    //   formData.append("image", data.image[0]);
    // }

    image.forEach((ele) => {
      formData.append("image", ele);
    });
    formData.append("isFeatured", isFeatured);
    formData.append("isActive", isActive);
    formData.append("description", description);
    formData.append("bnDescription", bnDescription);
    putRequest(`service/${_id}`, formData)
      .then((res) => {
        ToasterMessage("Data updated successfully!", "success");
        setIsLoading(false);
        navigate("/service");
      })
      .catch((err) => {
        ToasterMessage("Error Try Again!", "error");
        setIsLoading(false);
      });
    navigate("/service");
  };

  if (isFetching === true)
    return (
      <h2>
        Loading... <CircularProgress />
      </h2>
    );
  return (
    <>
      <ListCta path="/service" title="Service Update" btnName="Service List" />
      <Container>
        <Grid container>
          <Grid item xs={12} md={10} sx={{ m: "auto" }}>
            <Card sx={{ px: 5, py: 3 }}>

                <TextField
                  variant="outlined"
                  label="Name"
                  defaultValue={name}
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  onChange={(e) =>
                    setData((pre) => ({ ...pre, name: e.target.value }))
                  }
                  // {...register("name")}
                  required
                />
                <TextField
                  variant="outlined"
                  label="Bangla Name"
                  placeholder="Bangla Name"
                  defaultValue={bnName}
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  onChange={(e) =>
                    setData((pre) => ({ ...pre, bnName: e.target.value }))
                  }
                  // {...register("bnName")}
                  required
                />
                <TextField
                  variant="outlined"
                  label="Service Code"
                  defaultValue={serviceCode}
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  onChange={(e) =>
                    setData((pre) => ({ ...pre, serviceCode: e.target.value }))
                  }
                  // {...register("serviceCode")}
                  required
                />

                {/* <TextField
                  variant="outlined"
                  label="Description"
                  defaultValue={description}
                  sx={{ width: "100%", mb: 2 }}
                  size="small"
                  {...register("description")}
                /> */}

                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  config={editorConfig1}
                  onChange={(e, editor) =>
                    setData((pre) => ({
                      ...pre,
                      description: editor.getData(),
                    }))
                  }
                />

                <Box sx={{ mt: 2 }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={bnDescription}
                    config={editorConfig}
                    onChange={(e, editor) =>
                      setData((pre) => ({
                        ...pre,
                        bnDescription: editor.getData(),
                      }))
                    }
                  />
                </Box>
                <div style={{ display: "flex", padding: "3px" }}>
                {data.image && preview.length === 0 && (
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/images/serviceImage/${data.image}`}
                    width="300"
                    height="auto"
                    style={{ padding: "3px" }}
                    alt="no chinta"
                  />
                )}
              </div>
              <div style={{ display: "flex", padding: "3px" }}>
                {preview && (
                  <img
                    src={preview}
                    size="small"
                    width="300px"
                    style={{ padding: "3px" }}
                    alt="no chinta"
                  />
                )}
              </div>
                <TextField
                  variant="outlined"
                  sx={{ width: "100%", mb: 2, mt: 2 }}
                  size="small"
                  type="file"
                  onChange={handleImageChange}
                  name="Image"
                />
                {/* <FormControlLabel
                  label="Is Featured"
                  control={
                    <Switch
                      defaultChecked={isFeatured}
                      checked={isFeatured}
                      onChange={() =>
                        setData((prev) => ({
                          ...prev,
                          isFeatured: !prev.isFeatured,
                        }))
                      }
                    />
                  }
                /> */}
                {/* <FormControlLabel
                  label="Is Active"
                  control={
                    <Switch
                      checked={isActive}
                      onChange={() =>
                        setDataList((prev) => ({
                          ...prev,
                          isActive: !prev.isActive,
                        }))
                      }
                    />
                  }
                /> */}

                <Typography variant="caption" display="block">
                  Is Featured
                </Typography>
                <FormControl
                  sx={{
                    width: "100%",
                    mb: 2,
                  }}
                  size="small"
                >
                  <Select
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    name="isFeatured"
                    value={data.isFeatured}
                    onChange={(e) =>
                      setData((pre) => ({ ...pre, isFeatured: e.target.value }))
                    }
                    size="small"
                  >
                    <MenuItem value="true">true</MenuItem>
                    <MenuItem value="false">false</MenuItem>
                  </Select>
                </FormControl>

                <Typography variant="caption" display="block">
                  Is Active
                </Typography>
                <FormControl
                  sx={{
                    width: "100%",
                    mb: 2,
                  }}
                  size="small"
                >
                  <Select
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    name="isActive"
                    value={data.isActive}
                    onChange={(e) =>
                      setData((pre) => ({ ...pre, isActive: e.target.value }))
                    }
                    size="small"
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>

                <Box sx={{ mb: 2 }}>
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    sx={{
                      fontWeight: "600",
                      width: "100%",
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </LoadingButton>
                </Box>

            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Update;
