import http from "./http-common";
import { useState, useEffect } from "react";
import { useAuth } from "../authProvider/AuthContextProvider";

const useGetRequest = (url) => {
  const { token } = useAuth();
  const [dataList, setDataList] = useState([]);
  //const [servcieList,setServiceList]=useState([])
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    http
      .get(url, {
        headers: {
          Authorization: `NoChinta ${token}`,
        },
      })
      .then((res) => {
        setDataList(res.data);
        //setServiceList(res.data);
        setIsFetching(false);
      });
  }, [url]);
  return [isFetching, dataList, setDataList];
};

export default useGetRequest;
