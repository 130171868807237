import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { Link } from "react-router-dom";

const ListCta = ({ path, title, btnName, ctaTitle }) => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 5 }}>
        <h1>{title}</h1>
        <Link to={path}>
          <Button variant="outlined" color="primary" sx={{ mt: 3 }}>
            {btnName}
          </Button>
        </Link>
      </Box>
    </>
  );
};

export default ListCta;
