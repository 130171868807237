import { Link } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { useAuth } from "../../authProvider/AuthContextProvider";
import { useLocation } from "react-router-dom";

function Sidebar({ routes }) {
  const { user } = useAuth();
  const location = useLocation();

  const allRoutes = routes.map(
    (route, index) =>
      route.role === user.role && (
        <ListItem
          button
          component={Link}
          to={route.path}
          key={index}
          sx={{
            borderRight: location.pathname === route.path && 4,
            borderColor: location.pathname === route.path && "primary.main",
            backgroundColor: location.pathname === route.path && "#A2D2FF",
          }}
        >
          <ListItemIcon>{route.icon}</ListItemIcon>

          <ListItemText primary={route.routeName} />
        </ListItem>
      )
  );
  return (
    <div>
      <Toolbar>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ fontFamily: "Exo", fontWeight: 700 }}
        >
          No Chinta Ltd
        </Typography>
      </Toolbar>
      <Divider />
      <List>{allRoutes}</List>
      <div style={{ position: "fixed", bottom: 0, width: "260px" }}>
        <Divider />
        <Toolbar>
          <Typography variant="subtitle2" noWrap component="div">
            Developed by{" "}
            <a
              href="https://www.traideas.com/"
              target="_blank"
              style={{
                textDecoration: "none",
                fontWeight: 600,
              }}
            >
              Traideas
            </a>
          </Typography>
        </Toolbar>
      </div>
    </div>
  );
}

export default Sidebar;
