import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

const ServiceProvider = ({
  handleServiceProviderChange,
  serviceProvider,
  serviceProviderName,
  serviceProviderCode,
  agentFullName,
  contact,
  address,
}) => {
  return (
    <div>
      <Card
        sx={{
          px: 5,
          py: 3,
          boxShadow: 0,
          border: 1,
          borderColor: "lightgray",
          my: 3,
        }}
      >
        <Typography gutterBottom sx={{ typography: { xs: "h6", md: "h5" } }}>
          Service Provider
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            variant="outlined"
            label="Service Provider"
            placeholder="Service Provider"
            onChange={handleServiceProviderChange}
            name="serviceProviderName"
            defaultValue={serviceProviderName}
            sx={{ width: "100%", mt: 2, mr: 2 }}
            size="small"
          />

          <TextField
            variant="outlined"
            label="Provider Code"
            placeholder="Provider Code"
            onChange={handleServiceProviderChange}
            name="serviceProviderCode"
            defaultValue={serviceProviderCode}
            sx={{ width: "100%", mt: 2, mr: 2 }}
            size="small"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            variant="outlined"
            label="Service Agent"
            placeholder="Service Agent"
            onChange={handleServiceProviderChange}
            name="agentFullName"
            defaultValue={agentFullName}
            sx={{ width: "100%", mt: 2, mr: 2 }}
            size="small"
          />

          <TextField
            variant="outlined"
            label="Contact"
            placeholder="Contact"
            onChange={handleServiceProviderChange}
            name="contact"
            defaultValue={contact}
            sx={{ width: "100%", mt: 2, mr: 2 }}
            size="small"
          />
        </Box>

        <TextField
          variant="outlined"
          label="Address"
          placeholder="Address"
          onChange={handleServiceProviderChange}
          name="address"
          defaultValue={address}
          sx={{ width: "98%", mt: 2, mr: 2 }}
          size="small"
        />
      </Card>
    </div>
  );
};

export default ServiceProvider;
