import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

import useRequest from "../../apiServices/useRequest";
import useGetRequest from "../../apiServices/useGetRequest";
import NewInvitations from "./Create";
import Swal from "sweetalert2";

const domainURL = process.env.REACT_APP_DOMAIN_URL;

const List = () => {
  const [isFetching, invitationList, setInvitationList] =
    useGetRequest("/invite");

  const [, , deleteRequest] = useRequest();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUserDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRequest(`/invite/${id}`)
          .then((res) => {
            Swal.fire({
              icon: "success",
              text: "User deleted",
            });
            setInvitationList((prev) => prev.filter((item) => item._id !== id));
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              text: "Failed Try Again!",
            });
          });
      }
    });
  };
  if (isFetching)
    return (
      <h2>
        Loading... <CircularProgress />
      </h2>
    );
  return (
    <>
      <Box mt={2}>
        <Box sx={{ float: "right" }}>
          <Button variant="contained" onClick={handleClickOpen}>
            Invite new user
          </Button>
        </Box>
        <Typography variant="h4">Invitations</Typography>
        <Typography mb={2} variant="body2" gutterBottom>
          All invitions sent to new patentFutura users
        </Typography>

        <TableContainer
          component={Paper}
          sx={{ boxShadow: 0, border: 1, borderColor: "lightgray" }}
        >
          <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
            <TableHead sx={{ backgroundColor: "#F6F9FC" }}>
              <TableRow>
                <TableCell>FIRST NAME</TableCell>
                <TableCell>LAST NAME</TableCell>
                <TableCell>EMAIL</TableCell>
                {/* <TableCell>PHONE</TableCell> */}
                <TableCell>USER TYPE</TableCell>
                <TableCell>INVITATION LINK</TableCell>
                <TableCell>COPY LINK</TableCell>
                <TableCell align="center">ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invitationList.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                      mt={5}
                    >
                      <ConnectWithoutContactIcon
                        color="primary"
                        sx={{ fontSize: "100px", marginBottom: "20px" }}
                      />
                      <Typography variant="h5" sx={{ marginBottom: "20px" }}>
                        No Invitations sent
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                invitationList.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    {/* <TableCell>{row.phone}</TableCell> */}
                    <TableCell>{row.role}</TableCell>
                    <TableCell>{`${domainURL}/signup?regId=${row.register}`}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${domainURL}/signup?regId=${row.register}`
                          );
                        }}
                      >
                        {/* {row.pendingInvitation !== "Accepted" && <FileCopyIcon />} */}
                        {<FileCopyIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => handleUserDelete(row._id)}
                        color="error"
                        variant="outlined"
                      >
                        DELETE
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <NewInvitations
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
          setInvitationList={setInvitationList}
        />
      </Box>
    </>
  );
};

export default List;
