import React, { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

import signImage from "../../asstes/images/no-chinta-logo.svg";
import useRequest from "../../apiServices/useRequest";
import useGet from "../../apiServices/useGet";

import { useNavigate, useSearchParams } from "react-router-dom";

const initUser = {
  firstName: "",
  lastName: "",
  email: "",
};

const Registration = () => {
  const [user, setUser] = useState(initUser);
  const [searchParams] = useSearchParams();
  const regId = searchParams.get("regId");
  const [isLoading, setIsLoading] = useState(false);
  const [postRequest] = useRequest();
  const [getRequest] = useGet();
  const [errMessage, setErrMessage] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    //call an api and get the user data from the invitation URL
    getRequest(`/invite/response/${regId}`).then((res) => {
      //console.log(res);
      setUser(res);
    });
  }, [regId]);

  const handleFormChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleRegistration = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (user.password !== user.password_confirm) {
      setErrMessage("Password & Confirm Password does not match!");
      setIsLoading(false);
    } else {
      let finalUser = { ...user };
      delete finalUser.password_confirm;
      setUser(finalUser);
      //console.log("checking the final user", finalUser);
      postRequest(`/invite/response/${regId}`, finalUser)
        .then((res) => {
          navigate("/");
          setIsLoading(false);
        })
        .catch((err) => {
          setErrMessage("Faild try again!");
          setIsLoading(false);
        });
    }
  };

  // const onSubmit = async (data) => {
  //   setIsLoading(true);
  //   console.log("registration data is", data);
  //   if (data.password !== data.confirmPassword) {
  //     alert("password is not match");
  //     return;
  //   }
  //   postRequest("/auth/register", data)
  //     .then((res) => {
  //       ToasterMessage("Data saved successfully!", "success");
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       ToasterMessage("Error Try Again!", "error");
  //       setIsLoading(false);
  //     });
  // };

  return (
    <>
      <Box sx={{ my: 8 }}>
        <form onSubmit={handleRegistration}>
          <Card
            sx={{
              p: 5,
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              boxShadow: 3,
              m: "auto",
              width: { md: "30%", xs: "90%" },
            }}
          >
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <img src={signImage} alt="no chinta" style={{ width: "200px" }} />

              <Typography variant="h6" gutterBottom sx={{ mt: 1, mb: 2 }}>
                Welcome to No Chinta Ltd
              </Typography>
            </Box>

            <TextField
              sx={{ width: "100%", m: 1 }}
              label="First Name"
              required
              onChange={handleFormChange}
              name="firstName"
              value={user.firstName}
            />

            <TextField
              sx={{ width: "100%", m: 1 }}
              label="Last Name"
              required
              onChange={handleFormChange}
              name="lastName"
              value={user.lastName}
            />
            <TextField
              sx={{ width: "100%", m: 1 }}
              label="Email"
              onChange={handleFormChange}
              name="email"
              value={user.email}
            />

            <TextField
              sx={{ width: "100%", m: 1 }}
              label="Phone"
              onChange={handleFormChange}
              name="phone"
              value={user.phone}
            />
            <TextField
              sx={{ width: "100%", m: 1 }}
              label="Address"
              onChange={handleFormChange}
              name="address"
              value={user.address}
            />

            <TextField
              sx={{ width: "100%", m: 1 }}
              label="Password"
              type="password"
              required
              onChange={handleFormChange}
              name="password"
              value={user.password}
            />

            <TextField
              sx={{ width: "100%", m: 1 }}
              label="Confirm password"
              type="password"
              required
              onChange={handleFormChange}
              name="password_confirm"
              value={user.password_confirm}
            />

            {errMessage !== "" && (
              <Alert
                variant="outlined"
                severity="error"
                sx={{ width: "100%", m: 1 }}
              >
                {errMessage}
              </Alert>
            )}

            <LoadingButton
              loading={isLoading}
              variant="contained"
              sx={{
                fontWeight: "600",
                width: "100%",
                m: 1,
              }}
              type="submit"
            >
              Submit
            </LoadingButton>
            {/* <Typography variant="body1" gutterBottom sx={{ m: 1 }}>
              Already have an account? <Link to="/">Login</Link>{" "}
            </Typography> */}
          </Card>
        </form>
      </Box>
    </>
  );
};

export default Registration;
