import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useAuth } from "../authProvider/AuthContextProvider";

const AdminRoute = () => {
  const { user, token, isLoading } = useAuth();
  let location = useLocation();
  console.log(user);
  if (isLoading) {
    return <CircularProgress />;
  }
  if (token && user.email && user.status === true && user.roleCode === 1) {
    return <Outlet />;
  }
  return <Navigate to="/" state={{ from: location }} />;
};

export default AdminRoute;
