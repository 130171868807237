import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import useRequest from "../../apiServices/useRequest";

import ToasterMessage from "../../components/common/ToasterMessage";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const initState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  address: "",
};

const CreateCustomer = ({ handleClose, open, setDataList }) => {
  const [form, setForm] = useState(initState);

  const [isLoading, setIsLoading] = useState(false);
  const [postRequest, putRequest, deleteRequest] = useRequest();
  //const [userType, setUsertype] = useState();

  const handleFormChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const createCustomer = () => {
    console.log(form);
    setIsLoading(true);

    postRequest("customer", form)
      .then((res) => {
        setDataList((prev) => [...prev, res.data]);
        ToasterMessage("Data saved successfully!", "success");
        handleClose();
        setIsLoading(false);
      })
      .catch((err) => {
        ToasterMessage("Error Try Again!", "error");
        setIsLoading(false);
      });
  };

  return (
    <>
      <BootstrapDialog
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setForm(initState);
            handleClose();
          }
        }}
        //onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setForm(initState);
            handleClose();
          }}
        >
          Create new customer
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography sx={{ marginBottom: "1rem" }}>
            Customer details
          </Typography>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormControl sx={{ mb: 2 }} fullWidth>
                <TextField
                  label="First name"
                  size="small"
                  placeholder="Eg: Peter"
                  value={form.firstName}
                  onChange={handleFormChange}
                  name="firstName"
                />
              </FormControl>
              <FormControl sx={{ mb: 2, ml: 1 }} fullWidth>
                <TextField
                  label="Last name"
                  size="small"
                  placeholder="Eg: Parker"
                  value={form.lastName}
                  name="lastName"
                  onChange={handleFormChange}
                />
              </FormControl>
            </Box>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <TextField
                label="Phone"
                size="small"
                value={form.phoneNumber}
                name="phoneNumber"
                onChange={handleFormChange}
              />
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <TextField
                label="Email"
                size="small"
                placeholder="peter@email.com"
                value={form.email}
                name="email"
                onChange={handleFormChange}
              />
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <TextField
                label="Address"
                size="small"
                placeholder="Address"
                value={form.address}
                name="address"
                onChange={handleFormChange}
              />
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <TextField
                label="Note"
                size="small"
                placeholder="Note"
                value={form.note}
                name="note"
                onChange={handleFormChange}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            autoFocus
            onClick={() => {
              setForm(initState);
              handleClose();
            }}
            variant="outlined"
            color="error"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            variant="contained"
            color="success"
            onClick={createCustomer}
          >
            Create customer
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default CreateCustomer;
