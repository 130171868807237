import DashboardIcon from "@mui/icons-material/Dashboard";

import ScheduleIcon from "@mui/icons-material/Schedule";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import HistoryIcon from "@mui/icons-material/History";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";

const routes = [
  // *********************
  //     Staff Routes
  // *********************

  {
    path: "/dashboard",
    routeName: "Dashboard",
    icon: <DashboardIcon />,
    role: "staff",
  },
  {
    path: "/schedule/create",
    routeName: "Create Schedule",
    icon: <ScheduleIcon />,
    role: "staff",
  },

  {
    path: "/requested-services",
    routeName: "Requested Services",
    icon: <SupportAgentIcon />,
    role: "staff",
  },
  {
    path: "/ordered-services",
    routeName: "Ordered Services",
    icon: <BorderColorIcon />,
    role: "staff",
  },
  {
    path: "/service-history",
    routeName: "Service History",
    icon: <HistoryIcon />,
    role: "staff",
  },
  {
    path: "/customer",
    routeName: "Customers",
    icon: <PeopleAltIcon />,
    role: "staff",
  },
  // {
  //   path: "/provider",
  //   routeName: "Providers",
  //   icon: <PersonIcon />,
  //   role: "staff",
  // },
  // *********************
  //     Staff Routes
  // *********************

  // *********************
  //    Admin Routes
  // *********************
  {
    path: "/staff/list",
    routeName: "Staff Managment",
    icon: <ManageAccountsIcon />,
    role: "admin",
  },
  {
    path: "/service",
    routeName: "Services",
    icon: <MedicalInformationIcon />,
    role: "admin",
  },
  {
    path: "/blog",
    routeName: "Blog",
    icon: <NewspaperOutlinedIcon />,
    role: "admin",
  },
  {
    path: "/invitations",
    routeName: "Invitations",
    icon: <MedicalInformationIcon />,
    role: "admin",
  },
  

  // *********************
  //    Admin Routes
  // *********************
];

export default routes;
