import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/no-chinta-logo.svg";

import { useAuth } from "../../authProvider/AuthContextProvider";
const LoginPage = () => {
  const { loginUser, isLoading, authError } = useAuth();
  const { register, handleSubmit, reset } = useForm();
  let navigate = useNavigate();

  const onSubmit = (data) => {
    loginUser(data.email, data.password, navigate);
    reset();
  };
  return (
    <Box sx={{ backgroundColor: "#F7FCFF", minHeight: "100vh" }}>
      <Container>
        <Grid container>
          <Grid item xs={10} md={6} lg={5} sx={{ m: "auto" }}>
            <Box
              sx={{
                mt: { xs: 10, md: 30 },
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <img src={Logo} alt="No Chinta Ltd" />
                <Typography
                  sx={{
                    typography: { xs: "h6", md: "h4" },
                    mt: 5,
                  }}
                  gutterBottom
                >
                  Login
                </Typography>
              </Box>

              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  label="Email"
                  variant="outlined"
                  type="email"
                  sx={{ width: "100%", my: 1 }}
                  {...register("email")}
                  size="small"
                  required
                />

                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  sx={{ width: "100%", my: 1 }}
                  {...register("password")}
                  size="small"
                  required
                />

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "100%",
                    my: 2,
                  }}
                  //disabled={isLoading}
                >
                  Login
                </Button>
                {authError && <Alert severity="error">{authError}</Alert>}
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LoginPage;
