import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import useRequest from "../../apiServices/useRequest";

import toasterMessage from "../../components/common/ToasterMessage";

import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import useGetRequest from "../../apiServices/useGetRequest";
import CreateScheduleCustomer from "../../components/common/CreateScheduleCustomer";
import { previousDay } from "date-fns/esm";
import ConsumerDetails from "./components/ConsumerDetails";
import ServiceDetails from "./components/ServiceDetails";
import ServiceProvider from "./components/ServiceProvider";
import SessionList from "./components/SessionList";
import Note from "./components/Note";
import { IconButton } from "@mui/material";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const label1 = { inputProps: { "aria-label": "Switch demo" } };

const initialServiceOrder = {
  serviceCode: "",
  serviceName: "",
  serviceId: "",
  description: "",
  orderId: "",
  customer: "",
  staff: "",
  forMyself: "",
  contactPerson: "",
  consumerName: "",
  consumerAge: "",
  consumerSex: "",
  consumerContact: "",
  relationWithCustomer: "",
  locationArea: "",
  address: "dhaka",
  emergencyContact: "",
  serviceProvider: {
    serviceProviderName: "",
    serviceProviderCode: "",
    agentFullName: "",
    contact: "",
    address: "",
  },
  sessionList: [],
  sessionCount: 0,
  perSessionCost: 0,
  totalBill: "0",
  advancePaid: 0,
  activeSession: 0,
  completeSession: 0,
  cancelSession: 0,
  totalDue: 0,
  hasDue: "",
  paymentMethod: "",
  cashOnDelivery: "",
  requested: "",
  pending: "",
  onGoing: "",
  complete: "",
  active: "",
  status: "",
};

const Create = () => {
  const [isFetching, serviceList] = useGetRequest(`/service`);
  const [serviceData, setServiceData] = useState([]);
  const [customerNumber, setCustomerNumber] = useState({});
  const [serviceProvider, setServiceProvider] = useState({});
  const [sessionList, setSessionList] = useState([]);
  const [sessionDetail, setSessionDetail] = useState({});
  const [order, setOrder] = useState(initialServiceOrder);
  const [customerDetail, setCustomerDetail] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isCustomerExist, setIsCustomerExist] = useState(null);
  const [forMe, setForMe] = useState(false);
  const [user, setUser] = useState("");
  const [serviceDetails, setServiceDetails] = useState({});

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/rawData/serviceData.json`)
      .then((res) => res.json())
      .then((data) => {
        setServiceData(data);
      });
  }, []);

  const [locationData, setLocationData] = React.useState([]);

  React.useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/rawData/locationData.json`)
      .then((res) => res.json())
      .then((data) => {
        setLocationData(data);
      });
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [postRequest] = useRequest();

  const handleChange = (e) => {
    setCustomerNumber({ ...customerNumber, [e.target.name]: e.target.value });

    console.log("the phone number is", user.primaryContact);
  };

  const handleBookingChange = (e) => {
    setOrder({ ...order, [e.target.name]: e.target.value });
  };

  const handleServiceProviderChange = (e) => {
    setServiceProvider({ ...serviceProvider, [e.target.name]: e.target.value });
  };

  const handleSessionDetailChange = (e) => {
    setSessionDetail({
      ...sessionDetail,
      [e.target.name]: e.target.value,
      // startDate: value.toLocaleDateString(),
      // startTime: value.toLocaleTimeString(),
      // endDate: endDate.toLocaleDateString(),
      // endTime: endDate.toLocaleTimeString(),
      active: true,
    });
  };

  const handleAddService = (data) => {
    setSessionList((prevState) => [...prevState, data]);
    setSessionDetail((prev) => ({
      ...prev,
      sessionCost: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      comment: "",
    }));
  };

  /* 
  create a customer */

  const handleSearch = (e) => {
    e.preventDefault();
    setIsLoading(true);
    //console.log("search data is", customerNumber);
    postRequest("customer/key", customerNumber)
      .then((res) => {
        toasterMessage("Customer exists!", "success");
        console.log("response is", res);
        setCustomerDetail(res.data);
        if (res.status <= 210) {
          setIsCustomerExist(true);
          console.log("the data is", customerDetail);
        }
        // else if (res.status >= 210) {
        //   setIsCustomerExist(false);
        //   console.log("the data is", isCustomerExist);
        // }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.request.status >= 210) {
          setIsCustomerExist(false);
        }
        console.log("the data is", isCustomerExist);
        console.log("the error status code is", err);
        toasterMessage("Customer is not exists!", "warning");
        setIsLoading(false);
      });
  };

  const handleCheckBoxChange = (e) => {
    //console.log(forMe);
    if (!forMe) {
      setOrder((prev) => ({
        ...prev,
        consumerName: customerDetail.fullName,
        consumerContact: customerDetail.primaryContact,
        address: customerDetail.address,
      }));
    } else {
      setOrder((prev) => ({
        ...prev,
        consumerName: "",
        consumerContact: "",
        address: "",
      }));
    }
  };

  const handleReserveScedule = (e) => {
    e.preventDefault();
    setIsLoading(true);
    /*   let finalData = {
      ...booking,
      date: value.toLocaleDateString(),
      time: value.toLocaleTimeString(),
    }; */

    //console.log("the from entered value is ", order);

    const finalOrder = {
      ...order,
      serviceProvider: { ...serviceProvider },
      sessionList: sessionList,
      serviceCode: order.serviceName.serviceCode,
      requested: true,
      customer: customerDetail._id,
      serviceId: order.serviceName._id,
    };

    console.log("the final entered value is ", finalOrder);
    postRequest("order", finalOrder)
      .then((res) => {
        toasterMessage("Data saved successfully!", "success");
        setIsLoading(false);
      })
      .catch((err) => {
        toasterMessage("Error Try Again!", "error");
        setIsLoading(false);
      });

    // setOrder((prev) => ({
    //   ...prev,
    //   consumerName: "",
    //   consumerAge: "",
    //   consumerContact: "",
    //   address: "",
    //   relationWithCustomer: "",
    //   locationArea: "",
    //   serviceName: "",
    //   description: "",
    // }));

    setOrder(initialServiceOrder);

    // setServiceProvider((prev) => ({
    //   ...prev,
    //   serviceProviderName: "",
    //   serviceProviderCode: "",
    //   agentFullName: "",
    //   contact: "",
    //   address: "",
    // }));

    setServiceProvider(initialServiceOrder.serviceProvider);

    setSessionDetail((prev) => ({
      ...prev,
      sessionCost: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    /*   let finalData = {
      ...booking,
      date: value.toLocaleDateString(),
      time: value.toLocaleTimeString(),
    }; */

    //console.log("the from entered value is ", order);

    const finalOrder = {
      ...order,
      serviceProvider: { ...serviceProvider },
      sessionList: sessionList,
      serviceCode: order.serviceName.serviceCode,
      onGoing: true,
      customer: customerDetail._id,
      serviceId: order.serviceName._id,
      serviceName: order.serviceName.name, //bajey code por a servicename thik kora lagbey
    };

    console.log("the final entered value is ", finalOrder);
    postRequest("order", finalOrder)
      .then((res) => {
        toasterMessage("Data saved successfully!", "success");
        setIsLoading(false);
      })
      .catch((err) => {
        toasterMessage("Error Try Again!", "error");
        setIsLoading(false);
      });

    // setOrder((prev) => ({
    //   ...prev,
    //   consumerName: "",
    //   consumerAge: "",
    //   consumerContact: "",
    //   address: "",
    //   relationWithCustomer: "",
    //   locationArea: "",
    //   serviceName: "",
    //   description: "",
    // }));

    setOrder(initialServiceOrder);

    // setServiceProvider((prev) => ({
    //   ...prev,
    //   serviceProviderName: "",
    //   serviceProviderCode: "",
    //   agentFullName: "",
    //   contact: "",
    //   address: "",
    // }));

    setServiceProvider(initialServiceOrder.serviceProvider);

    setSessionDetail((prev) => ({
      ...prev,
      sessionCost: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
    }));
  };

  return (
    <>
      {/* 
      <ListCta
        path="/schedule"
        title="Schedule Create"
        btnName="Schedule List"
      /> */}
      <Box sx={{ mb: 5 }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Create Schedule
          </Typography>
          {/* <Typography variant="subtitle2" gutterBottom>
            Create a new service schedule
          </Typography> */}
        </Box>
      </Box>

      <Grid container spacing={5}>
        <Grid item xs={12} lg={8} sx={{ m: "auto" }}>
          {/* search functionality works here
           */}
          <Box>
            <TextField
              variant="outlined"
              label="Search client with phone number"
              placeholder="Search by Phone number"
              name="primaryContact"
              value={customerNumber.search}
              sx={{ width: "90%" }}
              size="small"
              onChange={handleChange}
              required
            />

            <Button
              variant="contained"
              sx={{ backgroundColor: "gray" }}
              type="submit"
              onClick={handleSearch}
            >
              <SearchIcon />
            </Button>
          </Box>
          {isCustomerExist === null ? (
            <></>
          ) : isCustomerExist === true ? (
            <>
              <Box sx={{ mt: 4 }}>
                <Card sx={{ px: 5, py: 2, width: "50%", m: "auto" }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={5}
                  >
                    <Stack>
                      <Typography variant="text">Name</Typography>
                      <Typography
                        variant="text"
                        sx={{ fontWeight: 600 }}
                        gutterBottom
                      >
                        {customerDetail.fullName}
                      </Typography>
                    </Stack>

                    <Stack>
                      <Typography variant="text">Phone</Typography>
                      <Typography
                        variant="text"
                        sx={{ fontWeight: 600 }}
                        gutterBottom
                      >
                        {customerDetail.primaryContact}
                      </Typography>
                    </Stack>

                    <Stack>
                      <Typography variant="text">Address</Typography>
                      <Typography
                        variant="text"
                        sx={{ fontWeight: 600 }}
                        gutterBottom
                      >
                        {customerDetail.address}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ mt: 4 }}>
                <Card sx={{ px: 4, py: 4, width: "70%", m: "auto" }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                    gutterBottom
                  >
                    Customer does not exists
                  </Typography>
                  <Box sx={{ textAlign: "center" }}>
                    <Button onClick={handleOpen} variant="outlined">
                      Create Customer
                    </Button>
                  </Box>
                </Card>
              </Box>
            </>
          )}
        </Grid>
        {/* 
          If customrer found  card showing here */}
        {/* 
          If customrer is not found  card showing here */}
        {/* <form onSubmit={handleSubmit}> */}
        <Grid item xs={12} lg={6}>
          {/* Customer Card */}
          <ConsumerDetails
            isCustomerExist={isCustomerExist}
            order={order}
            handleBookingChange={handleBookingChange}
            locationData={locationData}
            forMe={forMe}
            consumerName=""
            consumerAge=""
            consumerContact=""
            address=""
            relationWithCustomer=""
            locationArea=""
            consumerSex=""
          >
            <Box
              sx={{
                width: "49%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography sx={{ mt: 1.2, mr: 1 }}>For Client :</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setForMe(!forMe);
                      handleCheckBoxChange();
                    }}
                    //name="forMyself"
                    value={forMe}
                    checked={forMe}
                  />
                }
                label={order.forMyself}
              />
            </Box>
          </ConsumerDetails>
          <ServiceProvider
            handleServiceProviderChange={handleServiceProviderChange}
            serviceProvider={serviceProvider}
            serviceProviderName=""
            serviceProviderCode=""
            agentFullName=""
            contact=""
            address=""
          />
          <Box sx={{ textAlign: "left" }}>
            <Button
              loading={isLoading}
              variant="outlined"
              color="secondary"
              size="large"
              sx={{
                fontWeight: "600",
              }}
              //type="submit"
              onClick={handleReserveScedule}
            >
              Reserve Schedule
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} lg={6}>
          {/* Service Card */}

          <ServiceDetails
            order={order}
            handleBookingChange={handleBookingChange}
            serviceList={serviceList}
            setServiceDetails={setServiceDetails}
          />
          {/*Service provider section */}

          {/*session list create here */}
          <SessionList
            handleSessionDetailChange={handleSessionDetailChange}
            value={value}
            endDate={endDate}
            sessionDetail={sessionDetail}
            handleAddService={handleAddService}
            sessionList={sessionList}
            setValue={setValue}
            setEndDate={setEndDate}
          />
          {/* 
              Notes create here */}
          <Note handleBookingChange={handleBookingChange} order={order} />
          <Box sx={{ mb: 2, textAlign: "right" }}>
            <Button
              loading={isLoading}
              size="large"
              variant="contained"
              sx={{
                fontWeight: "600",
              }}
              type="submit"
              onClick={handleSubmit}
            >
              Confirm Schedule
            </Button>
          </Box>
        </Grid>
        {/* </form> */}
      </Grid>

      <CreateScheduleCustomer
        isFetching={isFetching}
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        customerDetail={customerDetail}
        setCustomerDetail={setCustomerDetail}
        customerNumber={customerNumber}
        CreateScheduleCustomer={CreateScheduleCustomer}
        setIsCustomerExist={setIsCustomerExist}
      />
    </>
  );
};

export default Create;
