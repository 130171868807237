import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useGetRequest from "../../apiServices/useGetRequest";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ToasterMessage from "../../components/common/ToasterMessage";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import useRequest from "../../apiServices/useRequest";
import ConsumerDetails from "../scheduleService/components/ConsumerDetails";
import ServiceDetails from "../scheduleService/components/ServiceDetails";
import ServiceProvider from "../scheduleService/components/ServiceProvider";
import Note from "../scheduleService/components/Note";

const Update = () => {
  let { id } = useParams();
  const [isFetching, dataList] = useGetRequest(`/order/${id}`);
  const [IsFetching, serviceList] = useGetRequest(`/service`);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [locationData, setLocationData] = React.useState([]);
  const [serviceProvider, setServiceProvider] = useState({});
  let navigate = useNavigate();
  React.useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/rawData/locationData.json`)
      .then((res) => res.json())
      .then((data) => {
        setLocationData(data);
      });
  }, []);
  const [postRequest, putRequest] = useRequest();

  const handleBookingChange = (e) => {
    setOrder({ ...order, [e.target.name]: e.target.value });
  };

  const handleServiceProviderChange = (e) => {
    setServiceProvider({ ...serviceProvider, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const finalOrder = { ...order, serviceProvider: { ...serviceProvider } };

    putRequest(`/order/${id}`, finalOrder)
      .then((res) => {
        ToasterMessage("Data updated successfully!", "success");
        setIsLoading(false);
      })
      .catch((err) => {
        ToasterMessage("Error Try Again!", "error");
        setIsLoading(false);
      });
  };

  if (isFetching === true)
    return (
      <h2>
        Loading... <CircularProgress />
      </h2>
    );
  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={10} sx={{ m: "auto" }}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              sx={{ mb: 2 }}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <form onSubmit={handleSubmit}>
              {/* Customer Card */}
              <ConsumerDetails
                order={order}
                handleBookingChange={handleBookingChange}
                locationData={locationData}
                consumerName={dataList.consumerName}
                consumerAge={dataList.consumerAge}
                consumerContact={dataList.consumerContact}
                address={dataList.address}
                relationWithCustomer={dataList.relationWithCustomer}
                locationArea={dataList.locationArea}
              />

              {/* Service Card */}
              <Box sx={{ mt: 3 }}>
                {/* <ServiceDetails
                  serviceList={serviceList}
                  name={dataList.serviceName.name}
                  handleBookingChange={handleBookingChange}
                /> */}
              </Box>

              {/*  <Card sx={{ px: 5, py: 3, mt: 5 }}>
                <Typography
                  gutterBottom
                  sx={{ typography: { xs: "h6", md: "h4" } }}
                >
                  Service Details
                </Typography>

                <FormControl
                  sx={{
                    backgroundColor: "#F8FDFE",
                    width: { md: "100%", xs: "100%" },
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Services
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Services"
                    required
                    onChange={handleBookingChange}
                    name="serviceName"
                    defaultValue={dataList.serviceName.name}
                  >
                    {serviceList.map((data) => (
                      <MenuItem key={data._id} value={data}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Card> */}

              {/* 
              service provider card  */}
              <ServiceProvider
                serviceProviderName={
                  dataList.serviceProvider?.serviceProviderName
                }
                serviceProviderCode={
                  dataList.serviceProvider.serviceProviderCode
                }
                agentFullName={dataList.serviceProvider.agentFullName}
                contact={dataList.serviceProvider.contact}
                address={dataList.serviceProvider.address}
                handleServiceProviderChange={handleServiceProviderChange}
              />

              {/* notes section */}
              <Note
                description={dataList.description}
                handleBookingChange={handleBookingChange}
              />

              <Box sx={{ mb: 2 }}>
                <Button
                  loading={isLoading}
                  variant="contained"
                  sx={{
                    fontWeight: "600",
                    width: "100%",
                  }}
                  type="submit"
                >
                  Confirm Update
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Update;
