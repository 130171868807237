import React, { useState } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import useGetRequest from "../../apiServices/useGetRequest";
import { Link } from "react-router-dom";

const List = () => {
  const [isFetching, dataList] = useGetRequest(`/order`);
  const filteredDataList = dataList.filter((row) => row.complete);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [copyList, setCopyList] = useState(dataList);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const requestSearch = (searched) => {
    setCopyList(
      filteredDataList.filter((item) => item.consumerContact.includes(searched))
    );
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredDataList.length) : 0;

  if (isFetching === true)
    return (
      <h2>
        Loading... <CircularProgress />
      </h2>
    );

  return (
    <>
      <Box sx={{ mb: 5 }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Service History
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            List of all the previous orders in NoChinta Ltd.
          </Typography>
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: 0, border: 1, borderColor: "lightgray" }}
      >
        {/* <TextField
          variant="outlined"
          placeholder="Search with customer phone numbers..."
          type="search"
          onInput={(e) => requestSearch(e.target.value)}
          fullWidth
          size="small"
        /> */}
        <Table sx={{ minWidth: 100 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#F6F9FC" }}>
            <TableRow>
              <TableCell align="center">Order No</TableCell>
              <TableCell>Service Name</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Total Bill Received</TableCell>
              {/* <TableCell>Due Payment</TableCell> */}
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredDataList.length === 0 ? (
              <Box sx={{ textAlign: "center" }}>
                <h3>No Orders are completed</h3>
              </Box>
            ) : (
              (copyList.length > 0 ? copyList : filteredDataList)

                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    hover
                  >
                    <TableCell component="th" scope="row" align="center">
                      {/* <b>{row.orderId}</b> */}{" "}
                      <b>{page * 10 + index + 1}</b>
                    </TableCell>
                    <TableCell>{row.serviceName}</TableCell>
                    <TableCell>{row.consumerName}</TableCell>
                    <TableCell>{row.consumerContact}</TableCell>
                    <TableCell>{row.totalBill}</TableCell>
                    {/* <TableCell>{row.totalDue}</TableCell> */}
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        as={Link}
                        to={`/ordered-services/detail/${row._id}`}
                      >
                        Details
                      </Button>
                    </TableCell>
                    {/*  <TableCell align="center">
                      <Link state={row} to={`/schedule/update/${row.name}`}>
                        <Button>
                          <EditIcon />
                        </Button>
                      </Link>
                      <Button
                        color="error"  onClick={() => handleDelete(row._id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredDataList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default List;
