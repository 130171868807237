import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import useRequest from "../../apiServices/useRequest";
import tosterMessage from "../../components/common/ToasterMessage";

const setSessionDetailInit = {
  startDate: "",
  endDate: "",
  paid: "",
  active: "",
  comment: "",
  sessionCost: 0,
  paymentMethod: "",
  cashOnDelivery: "",
  startTime: "",
  endTime: "",
};

const AddSessionModal = ({ handleClose, open, orderId, setOrderDetails }) => {
  const [sessionDetail, setSessionDetail] = useState(setSessionDetailInit);
  const [value, setValue] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [postRequest] = useRequest();
  const [isLoading, setIsLoading] = useState(false);
  const handleSessionDetailChange = (e) => {
    setSessionDetail({
      ...sessionDetail,
      [e.target.name]: e.target.value,
      // startDate: value.toLocaleDateString(),
      // startTime: value.toLocaleTimeString(),
      // endDate: endDate.toLocaleDateString(),
      // endTime: endDate.toLocaleTimeString(),
      active: true,
    });
  };
  const handleAddNewSchedule = () => {
    postRequest(`order/add/session/${orderId}`, sessionDetail)
      .then((res) => {
        console.log(res);
        setOrderDetails((prev) => ({
          ...prev,
          sessionList: res.data,
        }));
        tosterMessage("Session created successfully!", "success");
        setSessionDetail(setSessionDetailInit);
        setIsLoading(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        tosterMessage("Error Try Again!", "error");
        setIsLoading(false);
      });
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: 1200,
          },
        }}
      >
        <DialogTitle>ADD A NEW SESSION</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">START DATE</TableCell>
                  <TableCell align="left">START TIME</TableCell>
                  <TableCell align="left">END DATE</TableCell>
                  <TableCell align="left">END TIME</TableCell>
                  <TableCell align="left">SESSION COST</TableCell>
                  <TableCell align="left">COMMENT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <TextField
                      type="date"
                      onChange={handleSessionDetailChange}
                      name="startDate"
                      value={sessionDetail?.startDate}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      type="time"
                      onChange={handleSessionDetailChange}
                      name="startTime"
                      value={sessionDetail?.startTime}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      type="date"
                      onChange={handleSessionDetailChange}
                      name="endDate"
                      value={sessionDetail?.endDate}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      type="time"
                      onChange={handleSessionDetailChange}
                      name="endTime"
                      value={sessionDetail?.endTime}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      variant="outlined"
                      placeholder="Session Cost"
                      onChange={handleSessionDetailChange}
                      name="sessionCost"
                      value={sessionDetail?.sessionCost}
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      variant="outlined"
                      placeholder="Comments"
                      onChange={handleSessionDetailChange}
                      name="comment"
                      value={sessionDetail?.comment}
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  key={sessionDetail.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">{sessionDetail.startDate}</TableCell>
                  <TableCell align="left">{sessionDetail.startTime}</TableCell>
                  <TableCell align="left">{sessionDetail.endDate}</TableCell>
                  <TableCell align="left">{sessionDetail.endTime}</TableCell>
                  <TableCell align="left">
                    {sessionDetail.sessionCost}
                  </TableCell>
                  <TableCell align="left">{sessionDetail.comment}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSessionDetail(setSessionDetailInit);
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleAddNewSchedule} variant="contained">
            Confirm new schedule
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddSessionModal;
